@import '../views-dir.less';
@import '../../assets/less/common/variables.less';


.tip-pagination {

  .ant-pagination-item a {
    font-family: 'Avenir';
    color: @secondary-color;
  }

  li.ant-pagination-prev > button.ant-pagination-item-link:disabled,
  li.ant-pagination-next > button.ant-pagination-item-link:disabled,
  li.ant-pagination-next > button.ant-pagination-item-link,
  li.ant-pagination-prev > button.ant-pagination-item-link  {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button.ant-pagination-item-link:not(:disabled){
    color: @secondary-color;
  }

  .notification-row {

    .isRead {
      top: -5px;
    }

    .time-updated {
      margin-top: 0px;
    }
  }
}

.notifications {
  
  

  &__header-list {
    width: auto;
    padding: 24px;
    margin: 0 -24px;
    min-height: 60px;
    position: relative;
    color: @secondary-color;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    transition: .12s ease-in-out;

    &:first-child {
      margin-top: -24px;
    }

    &:hover {
      background-color: @body;
    }

    &.unread {
      font-weight: 600;
      cursor: pointer;
    }

    .title {
      font-weight: 600;
      padding-right: 5px;
      color: @primary-color;
    }

    .time-updated {
      font-size: 12px;
      margin-top: 10px;
      color: @primary-color;

      display: flex;
      align-items: center;
      
      .anticon {
        font-size: 14px;
        padding-right: 6px;
      }
    }

    .isRead {
      position: absolute;
      top: 50%;
      right: 20px;

      width: 10px;
      height: 10px;
      border-radius: 100px;
      background: @primary-color;
    }
  }

  &__all-list {
    margin-top: 8vh;


    @media @max768 {
      margin-top: 10vh;

      .notification-right {
        flex-direction: column;
        align-items: flex-end;
      }
    }

    .text-primary {
      cursor: pointer;
      font-weight: 600;
      transition: .15s ease-in-out;

      &:hover {
        opacity: 0.9;
      }
    }

    .ant-table-thead {
      display: none;
    }
  
    .ant-pagination-item-active {
      background-color: @primary-color;
      
      a {
        font-family: 'Avenir';
        color: white;
      }
    }

    .ant-table-tbody {
      tr {
        cursor: pointer;
      }
    }

    .notifications__header-list {
      margin: 0;
      padding: 0;
      border-bottom: 0;

      .time-updated {
        margin-top: 0;
        padding-right: 2rem;
        color: @primary-color;
      }

      &:hover {
        background: transparent;
      }

      @media (max-width: 768px) {
        display: block;
        .time-updated {
          margin-top: 10px;
          padding-right: 0;
        }
      }
    }
    
    

    .isRead {
        position: absolute;
        top: 30%;
        right: 15px;
  
        width: 10px;
        height: 10px;
        border-radius: 100px;
        background: @primary-color;
    }

    .time-updated {
        font-size: 10px;
        margin-top: 8px;
        color: @primary-color;
    }

    .bg-white {
      width: 85%;
      margin: auto;
    }
    
    .title {
        font-weight: 600;
        padding-right: 5px;
        color: @primary-color;

        &.no-padding {
          padding-right: 0px;
        }
    }

    .ant-table-tbody > tr > td {
      padding: 16px 1px !important;
    }

  }
}
.tip-pagination .notification-row .time-updated {
    margin-top: 0px;
    display: flex;
    align-items: center;
}
@primary-color: #6930a3;@secondary-color: #1b144c;@tertiary-color: #000025;@border-radius-base: 5px;