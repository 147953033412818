.loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0, 0.1);
    z-index: 999999;

    .ant-spin{
        transform: scale(1.7);
    }
}