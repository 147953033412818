


.breadcrumbs-container{
    background-color: @tertiary-color;
    padding: 8px;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 10;
}