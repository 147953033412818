.tips-card-actions {
  row-gap: 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 426px) {
    margin-top: 20px;
    row-gap: 0px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
  }
}

.detail-container {
  width: 700px;

  @media @max768 {
    width: 100%;
  }

  .detail-image-container {
    width: 100%;
    height: 431px;

    .ant-image-img {
      object-fit: cover;

      &.image_placeholder {
        object-fit: contain;
      }
    }
  }
}

.detail-content-container {
  padding: 20px 40px 20px 40px;

  @media @max768 {
    padding: 20px;
  }
}

.search-header {
  @media (max-width: 426px) {
    margin-bottom: 20px;
  }
}
