.mobile-download-report-btn-container {
  display: none;

  @media @max426 {
    bottom: 0px;
    margin-left: -23.75px;
    display: block;
    position: fixed;
    width: 100%;
    padding: 25px 35px 30px 25px;
    // border: 2px solid black;
    background-color: #f0f2f5;
  }
}

.download-report-btn {
  display: flex;

  @media @max426 {
    display: none;
  }
}

.social-media-share-container {
  margin-top: 10px;

  @media @max426 {
    margin-bottom: 60px;
    margin-top: 10px;
  }
}