@icon-image: "../../png/activity_group.png";
@medium-image: "../../png/hero-image-banner@medium.png";
.ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
        font-weight: 600;
    }
}

.ant-dropdown-menu-item-selected{
    background-color: lighten(@gray, 15%);
}

.ant-divider.ant-divider-horizontal.ant-divider-with-text.ant-divider-with-text-center {
    color: @white;
    border-color: @white;
    border-top: none !important;
}

.ant-menu-title-content {
    font-weight: 700;

    span {
        font-size: 16px !important;
    }
}



.ant-input-affix-wrapper {
    padding: 8px 11px !important;
}

.ant-picker-footer {
    display: none;
  }

.ant-picker-input {
    height: 30px;
}

.ant-tooltip.ant-slider-tooltip {
    z-index: 2 !important;
}


.ant-btn {
    height: 40px;
    font-weight: 600;

    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

a.ant-btn {
    line-height: 37px;
}

.ant-btn-default.default {
    background: #F3F3F7;
    color: #A8A8A8;
    border-color: rgba(#A8A8A8, 0.1);

    &:hover {
        background: darken(#F3F3F7, 3%)
    }
}



.ant-btn-leftIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    >span {
        width: auto;
        &:not(.anticon) {
            margin-left: 8px !important;
            margin-right: 0 !important;
        }
    }
}
.ant-btn-rightIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    
    >span {
        width: auto;
        &:not(.anticon) {
            margin-left: 0 !important;
            margin-right: 8px !important;
        }
    }
    >img {
        max-width: 18px;
    }
}
.ant-btn-outlined-secondary {
    color: @secondary-color;
    border-color: @secondary-color;

    &[disabled] {
        border-color: @border;

        &:hover {
            border-color: @border;
        }
    }

    &:hover {
        opacity: .8;
        color: @secondary-color;
        border-color: @secondary-color;
    }
}



//
.ant-select-item {
    color: @primary-color !important;
    background-color: transparent !important;
    
    div.ant-select-item-option-content {
        color: @primary-color;
    }
}

.ant-select-item[aria-selected="true"] {
    color: @white!important;
    background-color: @primary-color !important;
    
    div.ant-select-item-option-content {
        color: @white;
    }
}

.ant-tooltip-inner {
    color: @white !important;
}


.ant-form {

    .form-radio-button-group {
        max-width: 500px;
        width: 100%;
        display: flex;
        
        .ant-radio-button-wrapper {
            height: 45px;
            padding-top: 3px;
            font-weight: 600;
            padding-left: 20px;
            padding-right: 25px;
            flex: 1;
            background-color: @faded-gray;
            border: 1px solid #FFFFFF29;
            text-align: center;

            @media @max400 {
                padding-left: 20px;
                padding-right: 20px;
            }

            @media @max320 {
                padding-left: 15px;
                padding-right: 15px;
            }

            &::before {
                background-color: @faded-gray;
            }

            

            &.calendar-days {
                padding-left: 17px;
                padding-right: 20px;
                width: 45px;
                white-space: nowrap;
                border-radius: 0px;
                height: 45px;
                
            }

            & > span + span {
                color: @dark-gray;
                text-align: center;
                font-size: 16px;
                letter-spacing: 3px;

                @media @max400 {
                    font-size: 14px;
                    letter-spacing: 1px;
                }
            }

            &.selected  {
                background: @primary-color;
                color: @white;

                span + span {
                    color: @white;
                }
            }

            &.ant-radio-button-wrapper-checked:focus-within{
                box-shadow: none;
                border-color: 1px solid #d9d9d9;
            }

            .ant-radio-button-checked{
                border-color: 1px solid #d9d9d9 !important;
            }

            &.ant-radio-button-wrapper-checked {
                span.ant-radio-button.ant-radio-button-checked{
                    border-color: 1px solid #d9d9d9 !important;
                }

                &:not(.ant-radio-button-wrapper-disabled){
                    border-color: #d9d9d9;
                }
            }

            &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
                background-color: #d9d9d9;
            }

            &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
                border-color: 1px solid #d9d9d9 !important;
            }
        }
    }

    label {
        font-size: 12px !important;
        color: @secondary-color;
    }
    &-item-explain.ant-form-item-explain-error {
        padding: 10px 8px 20px 0px;
    }

    // Change placeholder color
    .ant-input::placeholder,
    .ant-picker-input > input::placeholder,
    .ant-picker-input > input
    {
        color: @primary-color;
        font-weight: 600;
    }

    .unregistered-partner-input::placeholder {
        color: @dark-gray !important;
    }

    .ant-picker-input {

        svg {
            fill: @primary-color;
        }
    }

    .ant-input {
        font-weight: 600;
    }

    .ant-select-selection-placeholder {
        color: @dark-gray;
        font-size: 14px;
        font-weight: 600;
    }

    .ant-select-arrow > * {
        color: @primary-color;
    }

    .ant-select-selection-item {
        font-size: 14px;
        font-weight: 600;
    }

    .ant-input-number {
        .ant-input-number-handler-wrap {
            display: none;
        }
    }

    .ant-upload.ant-upload-select-picture-card {
        width: 100%;
        height: 272px;
        text-align: center;
        vertical-align: top;
        background-color: #fafafa;
        border: 2px dashed #d9d9d9;
        border-radius: 4px;
        cursor: pointer;
        transition: border-color 0.3s;
    }


    .merchant-image-preview {
        width: 100%;
        height: 272px;
    }

    

    .ant-upload-list-picture-card-container{
        display: inline-block;
        width: 100%;
        height: 272px;
        vertical-align: top;
    }

    .sd-upload-button {
        font-size: 16px;
        font-weight: 900;
        color: @secondary-color;
    }

    .ant-input-affix-wrapper {

        input {
            color: @primary-color;
        }

        input::placeholder {
            color: #ac9c9c;
        }
    }


    &.sd-form {

        .ant-input-prefix {
            margin-right: 0px;
        }
        
    }

}

//Default Ant Button

button[class="ant-btn"]{
    color: @primary-color;
    font-weight: 600;
}

.ant-tooltip-content {
    .ant-tooltip-arrow > .ant-tooltip-arrow-content {
        background-color: @white;
    }

    .ant-tooltip-inner {
        background-color: @white;
        color: @secondary-color;
    }
}


.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    height: 40px;
    font-size: 18px
}

.ant-select.ant-select-auto-complete.ant-select-single.ant-select-show-search {
    height: 40px;
    font-size: 16px;

    .ant-select-selector {
        height: 100%;

        span > input {
            height: 100%;
        }
    }
}

.ant-menu-inline {
    .ant-menu-item::after {
        right: auto;
        left: 0;
    }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: fade(@primary-color,25%);
    color: #ffffff;
}

.anticon svg {
    vertical-align: middle;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 48px;
    line-height: 48px;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    width: 80px;
}

.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 calc(50% - 24px / 2);
}

.ant-form-item {
    margin-bottom: 18px !important;
}
.ant-form-item-with-help {
    margin-bottom: 0 !important;
}
.ant-form-item-explain{
    // min-height: 18px !important;
    font-size: 12px !important;
}

.ant-input[disabled]{
    color: rgb(0 0 0 / 48%) !important;
}

a {
    color: @secondary-color;
}

.ant-form-item-control-input-content{
    & > input {
        height: 40px;
        color: @primary-color;
    }

    .ant-select {
        color: @primary-color;
        .ant-select-selector {
            height: 40px !important;
            align-items: center;
        }
    }
}

.ant-table-thead > tr > th {
    background: @secondary-color !important;
    font-weight: 600 !important;
}

.ant-tabs-tab-btn {
    font-weight: 600;
    color: @dark-gray;
}

.ant-layout-sider-children {
    background-color: @white;
}

@media @max768 {
    .ant-menu-inline-collapsed-tooltip {
        display: none !important;
    }
}

/***** Secret Dates Custom Classes *****/

//Antd Notification

.ant-notification {
    
    .sd-notification {
        background: @primary-color;
        
        .ant-notification-notice-message,
        .ant-notification-notice-description {
            color: @white !important;
        }
    }
}

//Badge
.sd-badge {
    
    .ant-badge-count {
        box-shadow: none;
        background: @primary-color;
        font-weight: 600;
    }
    .ant-menu-title-content span {
        font-size: 16px !important;
        text-align: center;
        display: flex;
        align-self: center;
    }
    
}

.sd-collapse {

    &.ghost {

        .ant-collapse-item {
            background-color: @secondary-color;
            
            border-radius: 10px !important ;

            .ant-collapse-header {
                color: @white;
                font-weight: 600;
                font-size: 16px;

                &[aria-expanded=false]{

                    .ant-collapse-arrow {

                        transform: translateY(-50%) rotate(90deg);
                        
                    }
                }

                &[aria-expanded=true]{

                    .ant-collapse-arrow {

                        transform: translateY(-50%) rotate(180deg);
                        
                    }
                }

                
            }
        }
    }
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer !important;
}

//DATE PICKER
.react-datepicker__current-month{
    color: #121212 !important;;
}

.react-datepicker__month-read-view{
    font-family: 'Avenir';
    visibility: visible;
    display: flex;
    background-color: #ffffff;
    flex-direction: row;
    width: 95px;
    padding: 4px;
    margin-top: 4px;
    border: 1px solid #121212 !important;
    .react-datepicker__month-read-view--down-arrow{
        right: 7px;
        top: 8px;
        border-color: #6930a3;
        border-width: 1px 1px 0 0;
        height: 7px;
        width: 7px;
    }
    .react-datepicker__month-read-view--selected-month {
        margin-left: 4px;
        color: #6930a3;
        font-weight: 600;
    }
}

.react-datepicker__month-container{
    font-family: 'Avenir';
}

.react-datepicker__header{
    background-color: #ffffff !important;
    margin-top: 5px;
}
.react-datepicker__navigation--previous {
    margin-top: 10px;
}
.react-datepicker__navigation--next{
    margin-top: 10px;
}
.react-datepicker__month-dropdown{
    background-color: #ffffff !important;
    width: 66% !important;
    left: 6% !important;
    // height: 150px;
    // overflow-y: scroll;
}
.react-datepicker__month-option--selected{
    color: #6930a3;
}
// .react-datepicker__year-dropdown::after{
//     color: #6930a3 !important;
//     content: "\25BC";
//     display: block;
//     position: relative;
//     bottom: 20px;
// }
.react-datepicker__year-dropdown .react-datepicker__year-option:last-child::after {
    color: #6930a3 !important;
    content: unset !important;
    display: block;
    position: relative;
}

// .react-datepicker__navigation:first-child {
    // height: 25px !important;
    // background-position-x: center;
    // background-position-y: center;
// }
// .react-datepicker__navigation:last-child{
    // height: 25px !important;
    // background-position-x: center;
    // background-position-y: 4px;
    // height: 25px !important;
    // margin-top: 5px;
// }

.react-datepicker__year-option:last-of-type{
    a{
        height: 25px !important;
    }
}
.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
    // content: "\25B2";
    position: absolute;
    top: 4%;
    left: 45%;
    margin: auto;
    color: #6930a3 !important;
    a{
        background:url('https://i.stack.imgur.com/ZrSlg.jpg');
    }
}

// .react-datepicker__navigation--years-upcoming{
//     background-image:url('https://i.stack.imgur.com/ZrSlg.jpg') !important;
//     // background-image:"url('../../png/activity_group.png')" !important;
//     // background-image: url("@{medium-image}");
// }
.react-datepicker__month-option{
    line-height: 25px;
}
.react-datepicker__month-option:hover{
    background-color: #6930a378 !important;
}

.react-datepicker__day:hover{
    background-color: #6930a378 !important;
}
.react-datepicker__year-option:hover{
    background-color: #6930a378 !important;
}
.react-datepicker__year-option{
    line-height: 25px !important;
}
.react-datepicker__year-dropdown--scrollable{
    background-color: #ffffff !important;
    width: 66% !important;
    left: 25% !important;
    height: 242px !important;
}
.react-datepicker__day--keyboard-selected{
    background-color: #6930a3 !important;
}
.react-datepicker__input-container{
    input{
        font-family: 'Avenir';
        height: 38px;
        border-radius: 5px;
        padding-left: 10px;
        color: #6930a3;
        font-weight: 600;
        width: 100%;
        outline: unset !important;
        border: #ffffff 1px solid !important;
        cursor: pointer;
    }
    input::placeholder { color: #b2a1a1; }
    input:focus-visible{
        outline: unset !important;
        border: #ffffff 1px solid !important;
    }
}
.react-datepicker-popper{
    z-index: 3 !important;
}

.ant-form-item-has-error{
    .react-datepicker__input-container{
        input{
            font-family: 'Avenir';
            height: 38px;
            border-radius: 5px;
            padding-left: 10px;
            color: #6930a3;
            font-weight: 600;
            width: 100%;
            outline: unset !important;
            border: red 1px solid !important;
            cursor: pointer;
        }
        input::placeholder { color: #b2a1a1; }
        input:focus-visible{
            outline: unset !important;
            border: red 1px solid !important;
        }
    }
}
.react-datepicker__year-option--selected{
    color: #6930a3;
}

.empty-input{
    
    .react-datepicker__day--keyboard-selected {
        background-color: #8556b4b0 !important;
    }
}

.react-datepicker__year-read-view{
    font-family: 'Avenir';
    visibility: visible;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    width: 95px;
    padding: 4px;
    margin-top: 6px;
    margin-left: 4px;
    border: 1px solid #121212 !important;
    .react-datepicker__year-read-view--down-arrow{
        right: 7px;
        top: 8px;
        border-color: #6930a3;
        border-width: 1px 1px 0 0;
        height: 7px;
        width: 7px;
    }
    .react-datepicker__year-read-view--selected-year {
        margin-left: 4px;
        color: #6930a3;
        font-weight: 600;
    }
}

.react-datepicker__day--selected{
    background-color: #6930a3 !important;
}

.react-datepicker-icon{
    position: absolute;
    bottom: 13px;
    right: 12px;
    width: 14px;
    height: 14px;
    fill: #6930a3;
    pointer-events: none;
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown{
    background-color: #ffffff !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.ant-modal.sd-datepicker {

    .dynamic-dialog-modal {

        .datepicker {

            &.ios {
                border-radius: 15px;

                .datepicker-header {
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 4px;
                    color: @secondary-color;
                }

                


                .datepicker-content {

                    .datepicker-wheel {
                        background-color: rgba(105, 48, 163, 0.2);
                        border-radius: 15px;
                    }
    
                    .datepicker-col-1:nth-child(1){
                        
                        .datepicker-wheel {
                            border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;
                        }
                    }

                    .datepicker-col-1:nth-child(2){
                        
                        .datepicker-wheel {
                            border-radius: 0px;
                        }
                    }

                    .datepicker-col-1:nth-child(3){
                        
                        .datepicker-wheel {
                            border-radius: 0px;
                            border-top-right-radius: 15px;
                            border-bottom-right-radius: 15px;
                        }
                    }

                    ul.datepicker-scroll {

                        li {
                            color: @secondary-color;

                            &.disabled {
                                color: rgba(105, 48, 163, 0.2);
                            }
                        }
                    }
                }

                .datepicker-navbar {

                    border-bottom: 1px solid rgba(105, 48, 163, 0.2);

                    .datepicker-navbar-btn {
                        color: @primary-color;
                    }
                }
            }
        }
    }
}

@media @max768 {

    input, .ant-select-selection-item, .ant-select-selection-search > input, .ant-select-selection-placeholder, input::placeholder {
        font-size: 16px !important;
        font-weight: 600 !important;
    }
    

}

.ant-modal-confirm .ant-modal-confirm-btns{
    float: none;
    display: flex;
    justify-content: center;
}

.sd-pagination .ant-pagination-item a{
    padding: 0px 12px;
}

 .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #ffffff !important;
    font-size: 12px;
    letter-spacing: -1px;
    display: flex;
    justify-content: center;
    margin-top: 4px;
    opacity: 0;
    transition: all 0.2s;
}

 .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #ffffff !important;
 }
 .sd-pagination .ant-pagination-item > a {
    font-family: 'Avenir';
    color: #1b144c;
    margin-top: 2px;
}


.ant-form-item-with-help .ant-form-item-explain{
    padding-bottom: 20px;
}

.sd-pagination .ant-pagination{
    @media @max426 {
        justify-content: end;
    }
}