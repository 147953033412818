@{manageCard} {
    border-radius: 10px;
    width: 334px;
    margin: 10px;
    background-color: #colors[bgWhite];
    overflow: hidden;

    &.detail {
        width: auto;
        flex-grow: 1;
    }

    @media @max768 {
        width: 100%;
    }

    @{manageCard}__header {
        height: 200px;
        min-width: 300px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    @{manageCard}__content {
        padding: @side-padding;
        h3 {
            font-family: Gotham;
            font-size: 28px;
            margin: 0;
            margin-top: 10px;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;

            @media @max768 {
                font-size: 6vw;
            }
        }

        h3.no-info-placeholder {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-size: 20px;
            color: rgba(0, 0, 0, 0.25) !important;
        }

        h3:not(.text-blue) {
            color: #colors[primary];
        }
        .divider-line {
            margin: 10px 0;
            width: 20px;
            border-top: 2px solid #colors[breaker];

            &.green {
                border-top: 2px solid #colors[primary];
            }

            &.special {
                @media @max768 {
                    border-top: 0;
                    border-left: 2px solid #colors[breaker];
                    margin-right: 10px;
                    height: 30px;
                    &.green {
                        border-left: 2px solid #colors[primary];
                    }
                }
            }
        }
        label {
            font-size: 16px;
            white-space: pre-wrap;
            font-weight: 600;
            color: #colors[textColor];
            margin-bottom: 20px;
            display: block;
            
            &.summary {
                max-width: 150px;
                min-height: 50px;
            }
        }

        &.w-bg {
            background-color: #F8F9FD;
            border-radius: 10px;
        }

        &.special {
            @media @max768 {
                width: 100% !important;
                min-height: auto !important;
                margin: 10px !important;
                flex-direction: row !important;
                justify-content: flex-start !important;
                align-items: center;

                h3 {
                    max-width: 50%;
                }
            }
        }
    }

    @{manageCard}__footer {
        padding: 10px 20px;
        border-top: 1px solid #colors[breakerGray];

        button {
            font-size: 16px;
        }
    }

    &.horizontal {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        position: relative;

        @{manageCard}__header {
            min-height: 200px;
            height: 100%;

            @media @max768 {
                width: 100%;
                height: auto;

                &.w-img {
                    max-height: 200px;
                    width: 100% !important;

                    >img {
                        height: 100%;
                    }
                }
            }
        }
       
        @{manageCard}__content {
            margin: 20px 0;
            margin-bottom: 10px;
            flex-grow: 1;
            flex-basis: 0;
            position: relative;
            padding-bottom: 33px;

            @media @max768 {
                width: 100%;
            }

            h3 {
                margin-top: 0;
            }

            p {
                word-break: break-all;
            }

            @{manageCard}__inner-contact {
                border-top: 1px solid #E7E9EF;
                margin-top: 20px;
                padding: 20px 0;
            }
        }

        @{manageCard}__inner-footer {
            width: 100%;
            padding: 20px;
            border-top: 1px solid #E7E9EF;
        }

        @{manageCard}__inner-footer-s {
            width: 100%;
            padding: 15px;
            border-top: 1px solid #E7E9EF;
        }
    }

    &.horizontal__card-tip {
        display: flex;
        flex-wrap: wrap;
        width: 45%;

        @{manageCard}__header {
            min-height: 200px;
            height: 50%;

            @media @max768 {
                width: 100%;
                height: auto;
            }
        }
       
        @{manageCard}__content {
            margin: 20px 0;
            margin-bottom: 10px;
            flex-grow: 1;
            flex-basis: 0;
            position: relative;
            padding-bottom: 33px;

            @media @max768 {
                width: 100%;
            }

            h3 {
                margin-top: 0;
            }

            p {
                word-break: break-all;
            }

            @{manageCard}__inner-contact {
                border-top: 1px solid #E7E9EF;
                margin-top: 20px;
                padding: 20px 0;
            }

            @{manageCard}__inner-footer {
                position: absolute;
                left:0;
                bottom:0;
                width: calc(100% - 40px);
                margin: 0 20px;
                padding-top: 10px;
                border-top: 1px solid #E7E9EF;
            }

        }
    }

    &.horizontal__card-tip-detail {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @{manageCard}__header {
            min-height: 415px;
            height: 100%;

            @media @max768 {
                width: 100%;
                height: auto;
            }
        }
       
        @{manageCard}__content {
            margin: 20px 0;
            margin-bottom: 10px;
            flex-grow: 1;
            flex-basis: 0;
            position: relative;
            padding-bottom: 33px;

            @media @max768 {
                width: 100%;
            }

            h3 {
                margin-top: 0;
            }

            p {
                word-break: break-all;
            }

            @{manageCard}__inner-contact {
                border-top: 1px solid #E7E9EF;
                margin-top: 20px;
                padding: 20px 0;
            }

            @{manageCard}__inner-footer {
                position: absolute;
                left:0;
                bottom:0;
                width: calc(100% - 40px);
                margin: 0 20px;
                padding-top: 10px;
                border-top: 1px solid #E7E9EF;
            }

        }
    }

    &.horizontal__card-tip-other {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        min-height: 150px;

        @{manageCard}__header {
            min-height: 200px;
            height: 100%;

            @media @max768 {
                width: 100%;
                height: auto;
            }
        }
       
        @{manageCard}__content {
            margin: 20px 0;
            margin-bottom: 10px;
            flex-grow: 1;
            flex-basis: 0;
            position: relative;
            padding-bottom: 33px;

            @media @max768 {
                width: 100%;
            }

            h3 {
                margin-top: 0;
            }

            p {
                word-break: break-all;
            }

            @{manageCard}__inner-contact {
                border-top: 1px solid #E7E9EF;
                margin-top: 20px;
                padding: 20px 0;
            }

            @{manageCard}__inner-footer {
                position: absolute;
                left:0;
                bottom:0;
                width: calc(100% - 40px);
                margin: 0 20px;
                padding-top: 10px;
                border-top: 1px solid #E7E9EF;
            }

        }
    }

    .special-col {
        width: calc(100% - 358px);
        justify-content: center;
        display: flex;

        @media @max768 {
            width: 100%;
            margin-top: 20px;
        }
    }
}

@{manageCard3Column} {
    width: 100%;
    margin-bottom: 20px;
    grid-gap: 20px !important;

    .manage-card {
        margin: 0;
        flex: 1;

        >div.ant-space {
            padding: 10px 0;
        }
        &__content {
            h3 {
                font-weight: 600;
            }
        }
    
        @media @max1280 {  
          flex: 1 1 auto;
        }
        @media @max768 {
            &.w-img {
                flex: 1;
                min-width: 200px !important;

                .ant-space {
                    padding: 20px;
                }
                .ant-space-item {
                    width: 100%;
                }
            }
            &__content {
                padding: 0;
                
                h3 {
                    margin-top: 20px;
                }

                >div {
                    display: none;
                }

                label.summary {
                    max-width: unset;
                    margin-bottom: 0;
                }
            }
        }
    }

    &.column-special {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

        @media @max768 {
            display: flex;
            grid-template-columns: unset;
        }
    }
}

@{manageCard}-list {
    @media @max768 {
        flex-wrap: nowrap;
        overflow: auto;
        width: 100%;
    
        @{manageCard} {
            min-width: 270px;

            &.w-img {
                min-width: 180px;
                .ant-space {
                    flex-direction: column-reverse;
                    padding-top: 20px;
                    &.n-reverse {
                        padding-top: 0;
                        flex-direction: column;
                    }
                   
                }
            }
        }
    }
}

@manageCard : .manage-card;
@manageCard3Column: .manage-card-list-3column;
@side-padding: 0 20px;
#colors() {
    primary: @primary-color;
    secondary: @secondary-color;
    textColor: @b-black;
    bgWhite: @white;
    breaker: @blue;
    breakerGray: #E7E9EF;
}