@import '../../../views-dir.less';

.bottom-bar-container {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: @secondary-color;
    padding: 10px 15px;
    z-index: 9999;


    .bottom-bar-icon {
        color: @white;
        opacity: 0.5;

        &.active {
            opacity: 1;
        }
    }
}
@primary-color: #6930a3;@secondary-color: #1b144c;@tertiary-color: #000025;@border-radius-base: 5px;