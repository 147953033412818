@import '../../../../assets/less/common/variables.less';

.header-rightIcon {
    .ant-scroll-number {
        z-index: 1;
    }
    // @media @max400 {
    //     display: none;
    // }
}

.login-div {
    margin-right: 32px;
    margin-top: 5px;
}

.logintxt {
    margin: 0;
    @media @max426 {
        font-size: x-small;
        right: 30px;
        position: absolute;
        right: 1px;
    }
}

.join-now-btn {
    width: '100%';

    @media @max426 {
        height: 28px;
        width: 65px;
        font-size: x-small;
        left: 10px
    }
}

.skip-btn {
    width: '100%';

    @media @max426 {
        height: 28px;
        width: 65px;
        font-size: 14px;
        left: 10px
    }
}


.sd-nav-bar {
    width: 100%;
    // padding: 10px 25px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    &.modal-present {
        position: fixed;
    }

    

    .main-header {
        padding: 10px 25px;
        min-height: 64px;
        position: relative;

        .header-title {

            @media @max320 {
                display: none;
            }
        }

        
        .logo-container {

            @media @max320 {
                width: 100px;

                img {
                    width: 100%;
                }
            }
        }

        .tab-container {
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            // height: 100%;
            // margin: auto;
            // display: flex;

            .tab-wrapper {
                width: auto;
                

                .ant-tabs-nav {
                    margin: 0;

                    &::before {
                        border-bottom: none;
                    }

                    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
                    .ant-tabs-tab .ant-tabs-tab-btn
                     {
                        color: @white;
                    }
                }
            }
        }
    }

    .sub-header {
        border-top: 2px solid darken(@primary-color, 15);
        .header-tab {
            padding: 10px 15px;
            border-left: 0.5px solid darken(@primary-color, 15);
            p {
                margin: 0;
                text-align: center;
                color: @white;
                font-weight: 600;
            }
            
        }

        .active {
            border-bottom: 3px solid @primary-color;
        }
    }

    .breadcrumb-container {
        background-color: @tertiary-color;
        padding: 10px;

        .breadcrumb-indicator {
            margin: 0 5px;
            color: @dark-gray;
        }

        .breadcrumb-item {
            color: @white;

            &.active {
                color: @primary-color;
            }
        }
    }
}

.modal-notifications {
    position: absolute;
    right: 0px;
    top: 70px !important;
  
    .ant-modal-content {
      position: relative;
  
      &:before {
        content: "\A";
        position: absolute;
        right: 0;
        top: -10px;
        border-style: solid;
        border-width: 10px 10px 10px 0;
        border-color: transparent #ffffff transparent transparent;
      }

      .ant-modal-close {
        display: none;
      }
  
      .ant-modal-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >span {
            color: @secondary-color;
        }
  
        >a {
          font-size: 14px;
          font-weight: 600;
          color: @primary-color;
        }
      }

      .ant-modal-body {
        height: 500px !important;
      }
    }
  }

.tabs {
}

#sd-user-name {
    margin-top: 10px;
    margin-right: 4px;
}

.sd-user-avatar {
    border: 3px solid @primary-color;

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.menu-icon {

}

.desktop-tabs {
    
}

@primary-color: #6930a3;@secondary-color: #1b144c;@tertiary-color: #000025;@border-radius-base: 5px;