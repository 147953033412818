
.form-tab {
  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      .ant-tabs-ink-bar {
        left: 0px;
        height: 4px;
        max-width: 40px;
        background: @blue ;
        border-radius: 100px;
      }
      .ant-tabs-tab + .ant-tabs-tab {
        &::before{
          position: absolute;
          content: '';
          height: 19px;
          width: 1px;
          left: -16px;
          border-left: 1px solid #C6C6C6;
        }
      }
    }
  }
}