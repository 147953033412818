/* StripeCheckoutForm.less */

.stripe-form-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3em;

  img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }


  @media @max768 {
    width: 400px;
  }

  @media @max426 {
    width: 300px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.form {
  width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  @media @max768 {
    width: 400px;
  }

  @media @max426 {
    width: 300px;
  }
}

.stripe-success-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 20%;
  margin-right: 20%;
  height: 100vh;
  gap: 3em;
  text-align: center;

}

.stripe-form-reservation-title-container {
  width: "80%";
  word-break: break-all;
}

.stripe-form-text {
  color: @white;
}

.stripe-form-text-bold {
  color: @white;
  font-weight: 900;
}