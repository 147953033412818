.sidebar {
    height: 100vh;

    @media @max768 {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        z-index: 10000;

        &.ant-layout-sider-collapsed {
            margin-left: -80px;
        }
    }
}

.sidebar--overlay {
    content: '';
    position: fixed;
    z-index: -1;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
}