html,
body {
  overscroll-behavior-y: none;
}
body {
  margin: 0;
  font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(closest-side at 50% 50%, #1B144C 0%, #000025 100%) 0% 0% no-repeat padding-box;
  background-color: #000025;
  text-rendering: optimizeLegibility;
}
body.ant-scrolling-effect:has(.modal-notifications) {
  overflow-y: auto !important;
  width: 100% !important;
}
h1,
h2 {
  font-weight: 600;
}
.main-layout,
main.ant-layout-content {
  background: radial-gradient(closest-side at 50% 50%, #1B144C 0%, #000025 100%) 0% 0% no-repeat padding-box;
}
.d-header {
  position: sticky;
  top: 0;
  z-index: 3;
  background: #F3F3F7;
  padding-bottom: 0.5rem;
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #6930a3;
  transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill::selection,
input:-webkit-autofill:hover::selection,
input:-webkit-autofill:focus input:-webkit-autofill::selection,
textarea:-webkit-autofill::selection,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus::selection,
select:-webkit-autofill::selection,
select:-webkit-autofill:hover::selection,
select:-webkit-autofill:focus::selection {
  background-color: #6930a3;
  -webkit-text-fill-color: #ffffff;
}
input:-webkit-autofill::-moz-selection,
input:-webkit-autofill:hover::-moz-selection,
input:-webkit-autofill:focus input:-webkit-autofill::-moz-selection,
textarea:-webkit-autofill::-moz-selection,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus::-moz-selection,
select:-webkit-autofill::-moz-selection,
select:-webkit-autofill:hover::-moz-selection,
select:-webkit-autofill:focus::-moz-selection {
  background-color: #6930a3;
  -webkit-text-fill-color: #ffffff;
}
@font-face {
  font-family: 'Avenir';
  src: url('/src/assets/fonts/Avenir/Avenir.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.field-label {
  display: block;
  padding-bottom: 4px;
  font-weight: 500;
}
.field-label.required::after {
  display: inline-block;
  margin-left: 2px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.sd-link-btn {
  padding: 4px 15px;
  border-radius: 5px;
  color: #ffffff;
}
.sd-link-btn:hover {
  color: #ffffff;
}
.sd-phone-input {
  overflow: hidden;
  height: 40px;
}
.sd-phone-input .PhoneInput {
  display: flex;
  width: 100%;
  align-items: center;
}
.sd-phone-input .PhoneInput .PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.35em;
}
.sd-phone-input .PhoneInput .PhoneInputCountry .PhoneInputCountrySelectArrow {
  display: block;
  content: '';
  width: 0.3em;
  height: 0.3em;
  margin-left: 0.35em;
  border-style: solid;
  border-color: currentColor;
  border-top-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 1px;
  transform: rotate(45deg);
  opacity: 0.45;
}
.sd-phone-input .PhoneInput .PhoneInputCountry .PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}
.sd-phone-input .PhoneInput .PhoneInputCountry .PhoneInputCountryIcon {
  width: calc(1em * 1.5);
  height: 1em;
}
.sd-phone-input .PhoneInput .PhoneInputCountry .PhoneInputCountryIcon .PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
}
.sd-phone-input .PhoneInput > input {
  border: none;
  padding: 0px !important;
  font-weight: 600;
  flex: 1;
}
.sd-phone-input .PhoneInput > input::placeholder {
  font-size: 14px !important;
  font-weight: 600;
}
.sd-phone-input .PhoneInput > input:focus {
  outline: none;
}
.table-actions span {
  display: flex;
}
.form_body .ant-form-item-has-error input {
  border-color: #ff4d4f !important;
}
.form-radio-group {
  flex-direction: column;
  align-items: flex-start;
}
.form-radio-group .ant-form-item-label > label {
  color: #000025;
  font-size: 14px !important;
  font-weight: 600;
  margin-bottom: 10px;
}
.form-radio-group .ant-form-item-label > label::after {
  content: "";
}
.form-radio-group .ant-radio-wrapper span {
  color: #000025;
  font-size: 14px;
}
.form-radio-group.ant-form-item {
  margin-bottom: 8px !important;
}
.ant-btn.ant-btn-default.tag-buttons,
.ant-btn.tag-buttons {
  height: auto;
  padding: 15px 8px;
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 8px;
  border-color: #B2A1A1;
}
.ant-btn.ant-btn-default.tag-buttons.ant-btn-primary,
.ant-btn.tag-buttons.ant-btn-primary {
  border-color: #6930a3;
}
.simple-form-container {
  border-radius: 10px;
  padding: 30px;
}
.simple-form-container .avatar-uploader .ant-upload {
  width: 100%;
  height: 142px;
  overflow: hidden;
}
.simple-form-container .avatar-uploader .ant-upload-list-picture-card-container {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 130px;
}
.simple-form-container .avatar-uploader .ant-upload-list-picture-card-container .ant-upload-list-item-thumbnail img {
  object-fit: cover;
}
.simple-form-container .avatar-uploader .ant-upload-list-picture-card-container .ant-upload-list-item-actions {
  display: flex;
  align-items: center;
}
.simple-form-container .avatar-uploader .ant-upload-list-picture-card-container .ant-upload-list-item-actions > a {
  margin-top: -4px;
}
@media (max-width: 768px) {
  .simple-form-container {
    padding: 20px;
  }
  .simple-form-container .ant-col {
    min-width: 142px;
  }
}
.height-100 {
  height: 100%;
}
.max-width {
  width: 100%;
}
.width-300 {
  width: 300px;
}
.flex-flow-no-wrap {
  flex-flow: nowrap;
}
.mobile-header {
  max-width: 270px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.b-radius-20 {
  border-radius: 20px;
}
.b-radius-10 {
  border-radius: 10px;
}
.profile-img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #e7e7e7;
  color: #ffffff;
  font-size: 24px;
  text-align: center;
}
.d-block {
  display: block;
}
.visible-xs {
  display: none !important;
}
.d-i-block {
  display: inline-block;
}
.max-height {
  height: 100%;
}
.max-height-47 {
  height: 47%;
}
.icon-left .ant-picker-suffix {
  position: absolute;
  margin: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.85);
}
.icon-left input {
  padding-left: 18px !important;
}
.ant-form-item-has-error .icon-left .ant-picker-suffix {
  color: #ff4d4f;
}
.custom-tooltip {
  background: white;
  color: inherit;
  font-size: inherit;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
  padding: 5px 9px;
}
.icon-container {
  border-radius: 5px;
  padding: 8px;
}
.dynamic-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dynamic-info h2 {
  margin: 0;
  font-weight: 600;
}
.dynamic-info p {
  text-align: center;
}
.dynamic-info button[class="ant-btn"] {
  border: none;
  box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
  color: #1b144c;
}
.dynamic-info .schedule-info .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  height: 50px;
  overflow: hidden;
}
.dynamic-form-modal {
  display: flex;
  flex-direction: column;
}
.dynamic-form-modal .ant-input-affix-wrapper > input.ant-input {
  color: #6930a3;
}
.dynamic-form-modal .ant-input-affix-wrapper > input.ant-input::selection {
  color: #ffffff;
}
.dynamic-form-modal .ant-input-affix-wrapper > input.ant-input::-moz-selection {
  color: #ffffff;
}
.dynamic-form-modal .ant-input-prefix {
  font-weight: 600;
  color: #6930a3;
}
.dynamic-form-modal .no-title {
  padding-top: 30px;
}
.dynamic-form-modal .ant-modal-header > .ant-modal-title {
  margin: 0;
  font-weight: 600;
  text-align: left;
  font-size: 20px;
  color: #1b144c;
}
.dynamic-form-modal p {
  text-align: center;
  margin: 20px 0;
}
.dynamic-form-modal .description {
  color: #B2A1A1;
}
.dynamic-dialog-modal .experience-details .ant-card {
  border: none;
  border-radius: 15px;
}
.dynamic-dialog-modal .experience-details .ant-card .merchant-image {
  border-radius: 15px;
}
.dynamic-dialog-modal .experience-details .ant-card .header {
  margin-bottom: 8px;
  color: #1b144c;
}
.dynamic-dialog-modal .experience-details .ant-card .experience-info .label,
.dynamic-dialog-modal .experience-details .ant-card .experience-info .info {
  font-size: 14px;
}
.dynamic-dialog-modal .experience-details .ant-card .experience-info .label {
  margin-right: 12px;
  margin-bottom: 15px;
  color: #1b144c;
}
.dynamic-dialog-modal .experience-details .ant-card .experience-info .info {
  color: #B2A1A1;
  margin-bottom: 15px;
  font-weight: 600;
}
.dynamic-dialog-modal .experience-details .ant-card .experience-info .info span.label {
  color: #6930a3;
  font-weight: 600;
}
.dynamic-dialog-modal .experience-details .ant-card .experience-tags {
  padding: 5px 10px;
  background: #6930a3;
  color: #ffffff;
  font-weight: 600;
}
.dynamic-dialog-modal .experience-details .sd-pagination {
  margin-top: 50px;
}
.filter-view-container {
  height: 500px;
}
@media (max-width: 768px) {
  .filter-view-container {
    height: unset;
  }
}
.filter-view-container .filter-view-form {
  padding-bottom: 25px;
}
.filter-view-container .header {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
.filter-view-container .sd-simple-form .form-col-title {
  color: #ffffff;
  margin-bottom: 2rem;
}
.filter-view-container .sd-simple-form .form-col-subtitle {
  color: #B2A1A1;
  font-style: italic;
}
.filter-view-container .sd-simple-form .ant-form-item-label > label,
.filter-view-container .sd-simple-form .form-custom-label {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px !important;
}
.filter-view-container .sd-simple-form .ant-form-item-label > label::before,
.filter-view-container .sd-simple-form .form-custom-label::before {
  content: "" !important;
}
.bg-primary {
  background-color: #6930a3;
}
.bg-secondary {
  background-color: #1b144c;
}
.bg-tertiary {
  background-color: #000025;
}
.bg-dark-gray {
  background-color: #B2A1A1;
}
.bg-peach {
  background-color: #F7CB73;
}
.bg-green {
  background-color: #52C41A;
}
.bg-white {
  background-color: #ffffff;
}
.box-shadow {
  box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.1);
}
.padding-s {
  padding: 10px;
}
.padding-s-v {
  padding: 10px 0;
}
.pad-bottom-s {
  padding-bottom: 10px;
}
.padding-m {
  padding: 20px;
}
.padding-m-v {
  padding: 20px 0;
}
.pad-bottom-s-s {
  padding-bottom: 8px;
}
.r-margin {
  margin: 0 !important;
}
.margin-top-s {
  margin-top: 10px;
}
.margin-bottom-s {
  margin-bottom: 10px;
}
.margin-top-m {
  margin-top: 20px;
}
.margin-bottom-m {
  margin-bottom: 20px;
}
.margin-m-v {
  margin: 20px 0;
}
.margin-right-s {
  margin-right: 10px;
}
.margin-auto-r {
  margin-right: auto;
}
.space-left-s-xs {
  margin-left: 10px;
}
.margin-auto-l {
  margin-left: auto;
}
.gap-flex {
  gap: 20px !important;
}
.gap-10 {
  gap: 10px !important;
}
.gap-20 {
  gap: 20px !important;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px;
}
.font-26 {
  font-size: 30px;
}
.font-30 {
  font-size: 30px;
}
.font-60 {
  font-size: 60px;
}
.fw-600 {
  font-weight: 600;
}
.fw-400 {
  font-weight: 450;
}
.text-primary {
  color: #6930a3;
}
.text-secondary {
  color: #1b144c;
}
.text-tertiary {
  color: #000025;
}
.text-blue {
  color: #89D4F0;
}
.text-white {
  color: #ffffff;
}
.text-error {
  color: #EA3B3B;
}
.link-secondary,
.text-gray {
  color: #B2A1A1;
}
.link-primary:hover {
  color: #6930a3;
}
.link-secondary:hover {
  color: #6930a3;
}
.text-underline {
  text-decoration: underline;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.c-pointer {
  cursor: pointer;
}
.c-disabled {
  cursor: not-allowed;
}
.tableClass th {
  color: #6930a3;
}
.tableClassOne thead > tr > th {
  color: 'pink';
}
@media (max-width: 768px) {
  .visible-xs {
    display: block !important;
  }
  .max-width-xs {
    width: 100%;
  }
  .d-header,
  .hidden-xs {
    display: none;
  }
  .mobile-add {
    position: fixed;
    right: 14px;
    bottom: 14px;
    width: 50px !important;
    height: 50px !important;
    z-index: 1;
  }
  .space-top-m-xs {
    margin-top: 20px;
  }
  .space-left-s-xs {
    margin-left: 0;
  }
  .action-container {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .action-container * {
    width: 100%;
    margin: 0 !important;
    padding: 0;
  }
}
@media (max-width: 1280px) {
  .visible-lg {
    display: block !important;
  }
  .hidden-lg {
    display: none !important;
  }
}
.horizontal-scroll-container .horizontal-scroll-wrapper {
  flex-wrap: nowrap;
  overflow-x: hidden;
  padding: 0px 25px;
  scroll-behavior: smooth;
}
@media (max-width: 768px) {
  .horizontal-scroll-container .horizontal-scroll-wrapper {
    padding: 0px;
    overflow-x: scroll;
  }
}
.horizontal-scroll-container .horizontal-scroll-wrapper .arrow-left {
  position: absolute;
  left: 0;
  bottom: 6%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  color: #B2A1A1;
  font-size: 36px;
  z-index: 2;
  width: 25px;
}
@media (max-width: 768px) {
  .horizontal-scroll-container .horizontal-scroll-wrapper .arrow-left {
    display: none;
  }
}
.horizontal-scroll-container .horizontal-scroll-wrapper .arrow-right {
  position: absolute;
  right: 0;
  bottom: 6%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  color: #B2A1A1;
  font-size: 36px;
  z-index: 2;
  width: 25px;
}
@media (max-width: 768px) {
  .horizontal-scroll-container .horizontal-scroll-wrapper .arrow-right {
    display: none;
  }
}
.horizontal-scroll-container .scroll-title {
  color: #ffffff !important;
  margin: 2px;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .horizontal-scroll-container .scroll-title {
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  .horizontal-scroll-container .scroll-title {
    font-size: 14px;
  }
}
.horizontal-scroll-container .ant-card-hoverable {
  border-radius: 10px;
  overflow: hidden;
  transition: box-shadow 0.3s, border-color 0.3s, background-color 0.3s;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 350px;
  width: 270px;
  border: none;
}
@media (max-width: 1399px) {
  .horizontal-scroll-container .ant-card-hoverable {
    height: 300px;
    width: 240px;
  }
}
@media (max-width: 1199px) {
  .horizontal-scroll-container .ant-card-hoverable {
    height: 250px;
    width: 210px;
  }
}
@media (max-width: 1080px) {
  .horizontal-scroll-container .ant-card-hoverable {
    height: 250px;
    width: 190px;
  }
}
@media (max-width: 991px) {
  .horizontal-scroll-container .ant-card-hoverable {
    height: 220px;
    width: 170px;
  }
}
@media (max-width: 899px) {
  .horizontal-scroll-container .ant-card-hoverable {
    height: 200px;
    width: 170px;
  }
}
@media (max-width: 768px) {
  .horizontal-scroll-container .ant-card-hoverable {
    height: 350px;
    width: 270px;
  }
}
@media (max-width: 689px) {
  .horizontal-scroll-container .ant-card-hoverable {
    height: 300px;
    width: 240px;
  }
}
@media (max-width: 619px) {
  .horizontal-scroll-container .ant-card-hoverable {
    height: 280px;
    width: 210px;
  }
}
@media (max-width: 576px) {
  .horizontal-scroll-container .ant-card-hoverable {
    height: 250px;
    width: 190px;
  }
}
@media (max-width: 449px) {
  .horizontal-scroll-container .ant-card-hoverable {
    height: 200px;
    width: 140px;
  }
}
.horizontal-scroll-container .ant-card-hoverable .group-title {
  color: #ffffff;
  font-weight: 300;
}
.horizontal-scroll-container .ant-card-hoverable .group-title.name {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
@media (max-width: 449px) {
  .horizontal-scroll-container .ant-card-hoverable .group-title.name {
    font-size: 13px !important;
  }
}
.horizontal-scroll-container .ant-card-hoverable .group-title.date {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
@media (max-width: 1199px) {
  .horizontal-scroll-container .ant-card-hoverable .group-title.date {
    font-size: 18px;
  }
}
@media (max-width: 449px) {
  .horizontal-scroll-container .ant-card-hoverable .group-title.date {
    font-size: 13px !important;
  }
}
.horizontal-scroll-container .ant-card-hoverable .status-container {
  position: absolute;
  top: 15px;
  left: 15px;
}
.horizontal-scroll-container .ant-card-hoverable .status-container .status-tag {
  border: none;
}
.horizontal-scroll-container .ant-card-hoverable .status-container .status-tag.text-white {
  color: #ffffff;
}
.horizontal-scroll-container .ant-card-hoverable .status-container .status-tag.bg-dark-gray {
  background-color: #B2A1A1;
}
.horizontal-scroll-container .ant-card-hoverable .status-container .status-tag.bg-primary {
  background-color: #6930a3;
}
.horizontal-scroll-container .ant-card-hoverable .status-container .status-tag.bg-green {
  background-color: #52C41A;
}
.horizontal-scroll-container .ant-card-hoverable .status-container .status-tag.bg-secondary {
  background-color: #1b144c;
}
.horizontal-scroll-container .ant-card-hoverable .status-container .status-tag.bg-red {
  background-color: red;
}
@media (max-width: 768px) {
  .horizontal-scroll-container .ant-card-hoverable .status-container {
    top: 10px;
    left: 8px;
  }
  .horizontal-scroll-container .ant-card-hoverable .status-container .status-tag {
    font-size: 10px;
  }
}
@media (max-width: 320px) {
  .horizontal-scroll-container .ant-card-hoverable .status-container {
    top: 8px;
    left: 8px;
  }
  .horizontal-scroll-container .ant-card-hoverable .status-container .status-tag {
    font-size: 10px;
  }
}
@media (max-width: 768px) {
  .horizontal-scroll-container .ant-card-hoverable .group-title {
    font-size: 14px;
  }
  .horizontal-scroll-container .ant-card-hoverable .group-title.name {
    font-size: 14px;
  }
  .horizontal-scroll-container .ant-card-hoverable .group-title.date {
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  .horizontal-scroll-container .ant-card-hoverable {
    height: 250px;
    max-height: 250px;
  }
  .horizontal-scroll-container .ant-card-hoverable .ant-card-body {
    padding: 15px 10px;
  }
}
@media (max-width: 426px) {
  .horizontal-scroll-container .ant-card-hoverable {
    height: 200px;
    max-height: 200px;
  }
  .horizontal-scroll-container .ant-card-hoverable .group-title {
    font-size: 14px;
  }
  .horizontal-scroll-container .ant-card-hoverable .group-title.name {
    font-size: 14px;
  }
  .horizontal-scroll-container .ant-card-hoverable .group-title.date {
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  .horizontal-scroll-container .ant-card-hoverable {
    height: 150px;
    max-height: 150px;
  }
  .horizontal-scroll-container .ant-card-hoverable .ant-card-body {
    padding: 12px;
  }
  .horizontal-scroll-container .ant-card-hoverable .group-title {
    font-size: 12px;
  }
  .horizontal-scroll-container .ant-card-hoverable .group-title.name {
    font-size: 12px;
  }
  .horizontal-scroll-container .ant-card-hoverable .group-title.name {
    font-size: 16px;
  }
}
.horizontal-scroll-container .ant-card-hoverable .ant-card-cover img {
  height: calc(390px * 0.8);
}
.horizontal-scroll-container .ant-card-hoverable .ant-card-meta-title {
  color: #1b144c;
  font-weight: 900;
  transition: color 0.3s;
}
.horizontal-scroll-container .ant-card-hoverable:hover {
  background-color: #6930a3;
  border-color: #6930a3;
}
.horizontal-scroll-container .ant-card-hoverable:hover .ant-card-meta-title {
  font-weight: 900;
  color: #ffffff;
}
@media (max-width: 768px) {
  .horizontal-scroll-container {
    justify-content: center;
  }
}
.prefix-icon {
  color: #6930a3;
  font-weight: 900;
  font-size: 14px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  font-weight: 600;
}
.ant-dropdown-menu-item-selected {
  background-color: #f2f2f2;
}
.ant-divider.ant-divider-horizontal.ant-divider-with-text.ant-divider-with-text-center {
  color: #ffffff;
  border-color: #ffffff;
  border-top: none !important;
}
.ant-menu-title-content {
  font-weight: 700;
}
.ant-menu-title-content span {
  font-size: 16px !important;
}
.ant-input-affix-wrapper {
  padding: 8px 11px !important;
}
.ant-picker-footer {
  display: none;
}
.ant-picker-input {
  height: 30px;
}
.ant-tooltip.ant-slider-tooltip {
  z-index: 2 !important;
}
.ant-btn,
.sd-link-btn {
  height: 40px;
  font-weight: 600;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
a.ant-btn {
  line-height: 37px;
}
.ant-btn-default.default {
  background: #F3F3F7;
  color: #A8A8A8;
  border-color: rgba(168, 168, 168, 0.1);
}
.ant-btn-default.default:hover {
  background: #eaeaf1;
}
.ant-btn-leftIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-btn-leftIcon > span {
  width: auto;
}
.ant-btn-leftIcon > span:not(.anticon) {
  margin-left: 8px !important;
  margin-right: 0 !important;
}
.ant-btn-rightIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}
.ant-btn-rightIcon > span {
  width: auto;
}
.ant-btn-rightIcon > span:not(.anticon) {
  margin-left: 0 !important;
  margin-right: 8px !important;
}
.ant-btn-rightIcon > img {
  max-width: 18px;
}
.ant-btn-outlined-secondary {
  color: #1b144c;
  border-color: #1b144c;
}
.ant-btn-outlined-secondary[disabled] {
  border-color: #E7E9EF;
}
.ant-btn-outlined-secondary[disabled]:hover {
  border-color: #E7E9EF;
}
.ant-btn-outlined-secondary:hover {
  opacity: 0.8;
  color: #1b144c;
  border-color: #1b144c;
}
.ant-select-item {
  color: #6930a3 !important;
  background-color: transparent !important;
}
.ant-select-item div.ant-select-item-option-content {
  color: #6930a3;
}
.ant-select-item[aria-selected="true"] {
  color: #ffffff !important;
  background-color: #6930a3 !important;
}
.ant-select-item[aria-selected="true"] div.ant-select-item-option-content {
  color: #ffffff;
}
.ant-tooltip-inner {
  color: #ffffff !important;
}
.ant-form .form-radio-button-group {
  max-width: 500px;
  width: 100%;
  display: flex;
}
.ant-form .form-radio-button-group .ant-radio-button-wrapper {
  height: 45px;
  padding-top: 3px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 25px;
  flex: 1;
  background-color: #0C0928A3;
  border: 1px solid #FFFFFF29;
  text-align: center;
}
@media (max-width: 400px) {
  .ant-form .form-radio-button-group .ant-radio-button-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 320px) {
  .ant-form .form-radio-button-group .ant-radio-button-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.ant-form .form-radio-button-group .ant-radio-button-wrapper::before {
  background-color: #0C0928A3;
}
.ant-form .form-radio-button-group .ant-radio-button-wrapper.calendar-days {
  padding-left: 17px;
  padding-right: 20px;
  width: 45px;
  white-space: nowrap;
  border-radius: 0px;
  height: 45px;
}
.ant-form .form-radio-button-group .ant-radio-button-wrapper > span + span {
  color: #B2A1A1;
  text-align: center;
  font-size: 16px;
  letter-spacing: 3px;
}
@media (max-width: 400px) {
  .ant-form .form-radio-button-group .ant-radio-button-wrapper > span + span {
    font-size: 14px;
    letter-spacing: 1px;
  }
}
.ant-form .form-radio-button-group .ant-radio-button-wrapper.selected {
  background: #6930a3;
  color: #ffffff;
}
.ant-form .form-radio-button-group .ant-radio-button-wrapper.selected span + span {
  color: #ffffff;
}
.ant-form .form-radio-button-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:focus-within {
  box-shadow: none;
  border-color: 1px solid #d9d9d9;
}
.ant-form .form-radio-button-group .ant-radio-button-wrapper .ant-radio-button-checked {
  border-color: 1px solid #d9d9d9 !important;
}
.ant-form .form-radio-button-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked span.ant-radio-button.ant-radio-button-checked {
  border-color: 1px solid #d9d9d9 !important;
}
.ant-form .form-radio-button-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: #d9d9d9;
}
.ant-form .form-radio-button-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #d9d9d9;
}
.ant-form .form-radio-button-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: 1px solid #d9d9d9 !important;
}
.ant-form label {
  font-size: 12px !important;
  color: #1b144c;
}
.ant-form-item-explain.ant-form-item-explain-error {
  padding: 10px 8px 20px 0px;
}
.ant-form .ant-input::placeholder,
.ant-form .ant-picker-input > input::placeholder,
.ant-form .ant-picker-input > input {
  color: #6930a3;
  font-weight: 600;
}
.ant-form .unregistered-partner-input::placeholder {
  color: #B2A1A1 !important;
}
.ant-form .ant-picker-input svg {
  fill: #6930a3;
}
.ant-form .ant-input {
  font-weight: 600;
}
.ant-form .ant-select-selection-placeholder {
  color: #B2A1A1;
  font-size: 14px;
  font-weight: 600;
}
.ant-form .ant-select-arrow > * {
  color: #6930a3;
}
.ant-form .ant-select-selection-item {
  font-size: 14px;
  font-weight: 600;
}
.ant-form .ant-input-number .ant-input-number-handler-wrap {
  display: none;
}
.ant-form .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 272px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 2px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-form .merchant-image-preview {
  width: 100%;
  height: 272px;
}
.ant-form .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 100%;
  height: 272px;
  vertical-align: top;
}
.ant-form .sd-upload-button {
  font-size: 16px;
  font-weight: 900;
  color: #1b144c;
}
.ant-form .ant-input-affix-wrapper input {
  color: #6930a3;
}
.ant-form .ant-input-affix-wrapper input::placeholder {
  color: #ac9c9c;
}
.ant-form.sd-form .ant-input-prefix {
  margin-right: 0px;
}
button[class="ant-btn"] {
  color: #6930a3;
  font-weight: 600;
}
.ant-tooltip-content .ant-tooltip-arrow > .ant-tooltip-arrow-content {
  background-color: #ffffff;
}
.ant-tooltip-content .ant-tooltip-inner {
  background-color: #ffffff;
  color: #1b144c;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  height: 40px;
  font-size: 18px;
}
.ant-select.ant-select-auto-complete.ant-select-single.ant-select-show-search {
  height: 40px;
  font-size: 16px;
}
.ant-select.ant-select-auto-complete.ant-select-single.ant-select-show-search .ant-select-selector {
  height: 100%;
}
.ant-select.ant-select-auto-complete.ant-select-single.ant-select-show-search .ant-select-selector span > input {
  height: 100%;
}
.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(105, 48, 163, 0.25);
  color: #ffffff;
}
.anticon svg {
  vertical-align: middle;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 48px;
  line-height: 48px;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 calc(50% - 24px / 2);
}
.ant-form-item {
  margin-bottom: 18px !important;
}
.ant-form-item-with-help {
  margin-bottom: 0 !important;
}
.ant-form-item-explain {
  font-size: 12px !important;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.48) !important;
}
a {
  color: #1b144c;
}
.ant-form-item-control-input-content > input {
  height: 40px;
  color: #6930a3;
}
.ant-form-item-control-input-content .ant-select {
  color: #6930a3;
}
.ant-form-item-control-input-content .ant-select .ant-select-selector {
  height: 40px !important;
  align-items: center;
}
.ant-table-thead > tr > th {
  background: #1b144c !important;
  font-weight: 600 !important;
}
.ant-tabs-tab-btn {
  font-weight: 600;
  color: #B2A1A1;
}
.ant-layout-sider-children {
  background-color: #ffffff;
}
@media (max-width: 768px) {
  .ant-menu-inline-collapsed-tooltip {
    display: none !important;
  }
}
/***** Secret Dates Custom Classes *****/
.ant-notification .sd-notification {
  background: #6930a3;
}
.ant-notification .sd-notification .ant-notification-notice-message,
.ant-notification .sd-notification .ant-notification-notice-description {
  color: #ffffff !important;
}
.sd-badge .ant-badge-count {
  box-shadow: none;
  background: #6930a3;
  font-weight: 600;
}
.sd-badge .ant-menu-title-content span {
  font-size: 16px !important;
  text-align: center;
  display: flex;
  align-self: center;
}
.sd-collapse.ghost .ant-collapse-item {
  background-color: #1b144c;
  border-radius: 10px !important ;
}
.sd-collapse.ghost .ant-collapse-item .ant-collapse-header {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
}
.sd-collapse.ghost .ant-collapse-item .ant-collapse-header[aria-expanded=false] .ant-collapse-arrow {
  transform: translateY(-50%) rotate(90deg);
}
.sd-collapse.ghost .ant-collapse-item .ant-collapse-header[aria-expanded=true] .ant-collapse-arrow {
  transform: translateY(-50%) rotate(180deg);
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: pointer !important;
}
.react-datepicker__current-month {
  color: #121212 !important;
}
.react-datepicker__month-read-view {
  font-family: 'Avenir';
  visibility: visible;
  display: flex;
  background-color: #ffffff;
  flex-direction: row;
  width: 95px;
  padding: 4px;
  margin-top: 4px;
  border: 1px solid #121212 !important;
}
.react-datepicker__month-read-view .react-datepicker__month-read-view--down-arrow {
  right: 7px;
  top: 8px;
  border-color: #6930a3;
  border-width: 1px 1px 0 0;
  height: 7px;
  width: 7px;
}
.react-datepicker__month-read-view .react-datepicker__month-read-view--selected-month {
  margin-left: 4px;
  color: #6930a3;
  font-weight: 600;
}
.react-datepicker__month-container {
  font-family: 'Avenir';
}
.react-datepicker__header {
  background-color: #ffffff !important;
  margin-top: 5px;
}
.react-datepicker__navigation--previous {
  margin-top: 10px;
}
.react-datepicker__navigation--next {
  margin-top: 10px;
}
.react-datepicker__month-dropdown {
  background-color: #ffffff !important;
  width: 66% !important;
  left: 6% !important;
}
.react-datepicker__month-option--selected {
  color: #6930a3;
}
.react-datepicker__year-dropdown .react-datepicker__year-option:last-child::after {
  color: #6930a3 !important;
  content: unset !important;
  display: block;
  position: relative;
}
.react-datepicker__year-option:last-of-type a {
  height: 25px !important;
}
.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
  position: absolute;
  top: 4%;
  left: 45%;
  margin: auto;
  color: #6930a3 !important;
}
.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before a {
  background: url('https://i.stack.imgur.com/ZrSlg.jpg');
}
.react-datepicker__month-option {
  line-height: 25px;
}
.react-datepicker__month-option:hover {
  background-color: #6930a378 !important;
}
.react-datepicker__day:hover {
  background-color: #6930a378 !important;
}
.react-datepicker__year-option:hover {
  background-color: #6930a378 !important;
}
.react-datepicker__year-option {
  line-height: 25px !important;
}
.react-datepicker__year-dropdown--scrollable {
  background-color: #ffffff !important;
  width: 66% !important;
  left: 25% !important;
  height: 242px !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: #6930a3 !important;
}
.react-datepicker__input-container input {
  font-family: 'Avenir';
  height: 38px;
  border-radius: 5px;
  padding-left: 10px;
  color: #6930a3;
  font-weight: 600;
  width: 100%;
  outline: unset !important;
  border: #ffffff 1px solid !important;
  cursor: pointer;
}
.react-datepicker__input-container input::placeholder {
  color: #b2a1a1;
}
.react-datepicker__input-container input:focus-visible {
  outline: unset !important;
  border: #ffffff 1px solid !important;
}
.react-datepicker-popper {
  z-index: 3 !important;
}
.ant-form-item-has-error .react-datepicker__input-container input {
  font-family: 'Avenir';
  height: 38px;
  border-radius: 5px;
  padding-left: 10px;
  color: #6930a3;
  font-weight: 600;
  width: 100%;
  outline: unset !important;
  border: red 1px solid !important;
  cursor: pointer;
}
.ant-form-item-has-error .react-datepicker__input-container input::placeholder {
  color: #b2a1a1;
}
.ant-form-item-has-error .react-datepicker__input-container input:focus-visible {
  outline: unset !important;
  border: red 1px solid !important;
}
.react-datepicker__year-option--selected {
  color: #6930a3;
}
.empty-input .react-datepicker__day--keyboard-selected {
  background-color: #8556b4b0 !important;
}
.react-datepicker__year-read-view {
  font-family: 'Avenir';
  visibility: visible;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  width: 95px;
  padding: 4px;
  margin-top: 6px;
  margin-left: 4px;
  border: 1px solid #121212 !important;
}
.react-datepicker__year-read-view .react-datepicker__year-read-view--down-arrow {
  right: 7px;
  top: 8px;
  border-color: #6930a3;
  border-width: 1px 1px 0 0;
  height: 7px;
  width: 7px;
}
.react-datepicker__year-read-view .react-datepicker__year-read-view--selected-year {
  margin-left: 4px;
  color: #6930a3;
  font-weight: 600;
}
.react-datepicker__day--selected {
  background-color: #6930a3 !important;
}
.react-datepicker-icon {
  position: absolute;
  bottom: 13px;
  right: 12px;
  width: 14px;
  height: 14px;
  fill: #6930a3;
  pointer-events: none;
}
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #ffffff !important;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-modal.sd-datepicker .dynamic-dialog-modal .datepicker.ios {
  border-radius: 15px;
}
.ant-modal.sd-datepicker .dynamic-dialog-modal .datepicker.ios .datepicker-header {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  color: #1b144c;
}
.ant-modal.sd-datepicker .dynamic-dialog-modal .datepicker.ios .datepicker-content .datepicker-wheel {
  background-color: rgba(105, 48, 163, 0.2);
  border-radius: 15px;
}
.ant-modal.sd-datepicker .dynamic-dialog-modal .datepicker.ios .datepicker-content .datepicker-col-1:nth-child(1) .datepicker-wheel {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ant-modal.sd-datepicker .dynamic-dialog-modal .datepicker.ios .datepicker-content .datepicker-col-1:nth-child(2) .datepicker-wheel {
  border-radius: 0px;
}
.ant-modal.sd-datepicker .dynamic-dialog-modal .datepicker.ios .datepicker-content .datepicker-col-1:nth-child(3) .datepicker-wheel {
  border-radius: 0px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.ant-modal.sd-datepicker .dynamic-dialog-modal .datepicker.ios .datepicker-content ul.datepicker-scroll li {
  color: #1b144c;
}
.ant-modal.sd-datepicker .dynamic-dialog-modal .datepicker.ios .datepicker-content ul.datepicker-scroll li.disabled {
  color: rgba(105, 48, 163, 0.2);
}
.ant-modal.sd-datepicker .dynamic-dialog-modal .datepicker.ios .datepicker-navbar {
  border-bottom: 1px solid rgba(105, 48, 163, 0.2);
}
.ant-modal.sd-datepicker .dynamic-dialog-modal .datepicker.ios .datepicker-navbar .datepicker-navbar-btn {
  color: #6930a3;
}
@media (max-width: 768px) {
  input,
  .ant-select-selection-item,
  .ant-select-selection-search > input,
  .ant-select-selection-placeholder,
  input::placeholder {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: none;
  display: flex;
  justify-content: center;
}
.sd-pagination .ant-pagination-item a {
  padding: 0px 12px;
}
.ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #ffffff !important;
  font-size: 12px;
  letter-spacing: -1px;
  display: flex;
  justify-content: center;
  margin-top: 4px;
  opacity: 0;
  transition: all 0.2s;
}
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #ffffff !important;
}
.sd-pagination .ant-pagination-item > a {
  font-family: 'Avenir';
  color: #1b144c;
  margin-top: 2px;
}
.ant-form-item-with-help .ant-form-item-explain {
  padding-bottom: 20px;
}
@media (max-width: 426px) {
  .sd-pagination .ant-pagination {
    justify-content: end;
  }
}
.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 999999;
}
.loader .ant-spin {
  transform: scale(1.7);
}
.input-search.ant-input-affix-wrapper {
  border: 1px solid #89D4F0;
}
.input-search.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #5dc1e6;
}
.input-search.ant-input-affix-wrapper-focused {
  border-color: #5dc1e6;
  box-shadow: 0 0 0 2px rgba(106, 210, 236, 0.3);
}
.input-search .ant-input::placeholder {
  color: #014050;
}
.graph__legends {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
}
.graph__legends .legend {
  display: flex;
  align-items: center;
}
.graph__legends .legend-color {
  height: 16px;
  width: 16px;
  min-width: 16px;
  max-height: 16px;
}
.graph__legends .legend-label {
  font-size: 12px;
  line-height: 100%;
  margin-left: 0.3rem;
  font-weight: normal;
  white-space: nowrap;
  color: #1b144c;
}
.breadcrumbs-container {
  background-color: #000025;
  padding: 8px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 10;
}
.mobile-download-report-btn-container {
  display: none;
}
@media (max-width: 426px) {
  .mobile-download-report-btn-container {
    bottom: 0px;
    margin-left: -23.75px;
    display: block;
    position: fixed;
    width: 100%;
    padding: 25px 35px 30px 25px;
    background-color: #f0f2f5;
  }
}
.download-report-btn {
  display: flex;
}
@media (max-width: 426px) {
  .download-report-btn {
    display: none;
  }
}
.social-media-share-container {
  margin-top: 10px;
}
@media (max-width: 426px) {
  .social-media-share-container {
    margin-bottom: 60px;
    margin-top: 10px;
  }
}
.custom-table-pagination {
  background-color: none !important;
}
.custom-table-pagination .ant-table-cell {
  padding: 0px;
}
.custom-table-pagination .ant-table-cell:hover {
  background: none !important;
}
.custom-table-pagination .ant-table {
  background: none !important;
}
.dashboard-tab .ant-tabs-nav-wrap {
  justify-content: flex-end;
}
.dashboard-tab .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab::before {
  position: absolute;
  content: '';
  height: 19px;
  width: 1px;
  left: -16px;
  border-left: 1px solid #C6C6C6;
}
@media (max-width: 768px) {
  .dashboard-tab .ant-tabs-nav-wrap {
    justify-content: flex-start;
  }
}
.dashboard-tab.tab-left .ant-tabs-nav-wrap {
  justify-content: flex-start;
}
@media (max-width: 768px) {
  .main-tab {
    padding-top: 60px !important;
  }
  .main-tab > .ant-tabs-nav {
    position: fixed !important;
    top: 72px;
    left: 24px;
    background: #f0f2f5;
    width: 100%;
    z-index: 9;
  }
}
.manage-no-data-placeholder th,
.manage-no-data-placeholder td {
  background: none !important;
  border: none !important;
}
.manage-no-data-placeholder .ant-table {
  background: none !important;
}
@media (min-width: 768px) {
  .manage-no-data-placeholder .ant-empty-description {
    font-size: 17.5px !important;
  }
  .manage-no-data-placeholder .ant-empty-image {
    height: 100px !important;
  }
  .manage-no-data-placeholder .ant-empty-image svg {
    width: 120px;
    height: 100px;
  }
}
.details-no-data-placeholder th,
.details-no-data-placeholder td {
  background: none !important;
  border: none !important;
}
.details-no-data-placeholder .ant-table {
  background: none !important;
}
@media (min-width: 768px) {
  .details-no-data-placeholder .ant-empty-description {
    font-size: 17.5px !important;
  }
  .details-no-data-placeholder .ant-empty-image {
    height: 100px !important;
  }
  .details-no-data-placeholder .ant-empty-image svg {
    width: 120px;
    height: 100px;
  }
}
@media (max-width: 426px) {
  .details-no-data-placeholder .ant-empty-description {
    font-size: 17.5px !important;
  }
  .details-no-data-placeholder .ant-empty-image {
    height: 100px !important;
  }
  .details-no-data-placeholder .ant-empty-image svg {
    width: 325px;
    height: 75px;
  }
}
@media (max-width: 376px) {
  .details-no-data-placeholder .ant-empty-description {
    font-size: 17.5px !important;
  }
  .details-no-data-placeholder .ant-empty-image {
    height: 100px !important;
  }
  .details-no-data-placeholder .ant-empty-image svg {
    width: 280px;
    height: 75px;
  }
}
@media (max-width: 321px) {
  .details-no-data-placeholder .ant-empty-description {
    font-size: 17px !important;
  }
  .details-no-data-placeholder .ant-empty-image {
    height: 75px !important;
  }
  .details-no-data-placeholder .ant-empty-image svg {
    width: 225px;
    height: 60px;
  }
}
.single-values-field {
  min-width: 40%;
  max-width: 40%;
}
@media (max-width: 500px) {
  .single-values-field {
    min-width: 100%;
    max-width: 100%;
  }
}
.single-values-multi-field {
  max-width: 40%;
  width: 100%;
}
@media (max-width: 500px) {
  .single-values-multi-field {
    max-width: 100%;
  }
}
.multiple-values-multi-field {
  width: 100%;
}
@media (max-width: 500px) {
  .multiple-values-multi-field {
    padding-right: 0px;
  }
}
.multiple-values-remove-btn {
  cursor: pointer;
}
.multiple-values-action {
  margin-right: 20px;
  display: flex;
  align-items: flex-end;
  padding: 0px 0px 5px 0px;
}
@media (max-width: 768px) {
  .multiple-values-action {
    margin-top: 30px;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 20px;
  }
}
@media (max-width: 500px) {
  .multiple-values-action {
    padding-right: 0px;
  }
}
.form-tab .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
  left: 0px;
  height: 4px;
  max-width: 40px;
  background: #89D4F0;
  border-radius: 100px;
}
.form-tab .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab::before {
  position: absolute;
  content: '';
  height: 19px;
  width: 1px;
  left: -16px;
  border-left: 1px solid #C6C6C6;
}
.manage-card {
  border-radius: 10px;
  width: 334px;
  margin: 10px;
  background-color: #ffffff;
  overflow: hidden;
}
.manage-card.detail {
  width: auto;
  flex-grow: 1;
}
@media (max-width: 768px) {
  .manage-card {
    width: 100%;
  }
}
.manage-card .manage-card__header {
  height: 200px;
  min-width: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.manage-card .manage-card__content {
  padding: 0 20px;
}
.manage-card .manage-card__content h3 {
  font-family: Gotham;
  font-size: 28px;
  margin: 0;
  margin-top: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
@media (max-width: 768px) {
  .manage-card .manage-card__content h3 {
    font-size: 6vw;
  }
}
.manage-card .manage-card__content h3.no-info-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.25) !important;
}
.manage-card .manage-card__content h3:not(.text-blue) {
  color: #6930a3;
}
.manage-card .manage-card__content .divider-line {
  margin: 10px 0;
  width: 20px;
  border-top: 2px solid #89D4F0;
}
.manage-card .manage-card__content .divider-line.green {
  border-top: 2px solid #6930a3;
}
@media (max-width: 768px) {
  .manage-card .manage-card__content .divider-line.special {
    border-top: 0;
    border-left: 2px solid #89D4F0;
    margin-right: 10px;
    height: 30px;
  }
  .manage-card .manage-card__content .divider-line.special.green {
    border-left: 2px solid #6930a3;
  }
}
.manage-card .manage-card__content label {
  font-size: 16px;
  white-space: pre-wrap;
  font-weight: 600;
  color: #014050;
  margin-bottom: 20px;
  display: block;
}
.manage-card .manage-card__content label.summary {
  max-width: 150px;
  min-height: 50px;
}
.manage-card .manage-card__content.w-bg {
  background-color: #F8F9FD;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .manage-card .manage-card__content.special {
    width: 100% !important;
    min-height: auto !important;
    margin: 10px !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center;
  }
  .manage-card .manage-card__content.special h3 {
    max-width: 50%;
  }
}
.manage-card .manage-card__footer {
  padding: 10px 20px;
  border-top: 1px solid #E7E9EF;
}
.manage-card .manage-card__footer button {
  font-size: 16px;
}
.manage-card.horizontal {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  position: relative;
}
.manage-card.horizontal .manage-card__header {
  min-height: 200px;
  height: 100%;
}
@media (max-width: 768px) {
  .manage-card.horizontal .manage-card__header {
    width: 100%;
    height: auto;
  }
  .manage-card.horizontal .manage-card__header.w-img {
    max-height: 200px;
    width: 100% !important;
  }
  .manage-card.horizontal .manage-card__header.w-img > img {
    height: 100%;
  }
}
.manage-card.horizontal .manage-card__content {
  margin: 20px 0;
  margin-bottom: 10px;
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  padding-bottom: 33px;
}
@media (max-width: 768px) {
  .manage-card.horizontal .manage-card__content {
    width: 100%;
  }
}
.manage-card.horizontal .manage-card__content h3 {
  margin-top: 0;
}
.manage-card.horizontal .manage-card__content p {
  word-break: break-all;
}
.manage-card.horizontal .manage-card__content .manage-card__inner-contact {
  border-top: 1px solid #E7E9EF;
  margin-top: 20px;
  padding: 20px 0;
}
.manage-card.horizontal .manage-card__inner-footer {
  width: 100%;
  padding: 20px;
  border-top: 1px solid #E7E9EF;
}
.manage-card.horizontal .manage-card__inner-footer-s {
  width: 100%;
  padding: 15px;
  border-top: 1px solid #E7E9EF;
}
.manage-card.horizontal__card-tip {
  display: flex;
  flex-wrap: wrap;
  width: 45%;
}
.manage-card.horizontal__card-tip .manage-card__header {
  min-height: 200px;
  height: 50%;
}
@media (max-width: 768px) {
  .manage-card.horizontal__card-tip .manage-card__header {
    width: 100%;
    height: auto;
  }
}
.manage-card.horizontal__card-tip .manage-card__content {
  margin: 20px 0;
  margin-bottom: 10px;
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  padding-bottom: 33px;
}
@media (max-width: 768px) {
  .manage-card.horizontal__card-tip .manage-card__content {
    width: 100%;
  }
}
.manage-card.horizontal__card-tip .manage-card__content h3 {
  margin-top: 0;
}
.manage-card.horizontal__card-tip .manage-card__content p {
  word-break: break-all;
}
.manage-card.horizontal__card-tip .manage-card__content .manage-card__inner-contact {
  border-top: 1px solid #E7E9EF;
  margin-top: 20px;
  padding: 20px 0;
}
.manage-card.horizontal__card-tip .manage-card__content .manage-card__inner-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - 40px);
  margin: 0 20px;
  padding-top: 10px;
  border-top: 1px solid #E7E9EF;
}
.manage-card.horizontal__card-tip-detail {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.manage-card.horizontal__card-tip-detail .manage-card__header {
  min-height: 415px;
  height: 100%;
}
@media (max-width: 768px) {
  .manage-card.horizontal__card-tip-detail .manage-card__header {
    width: 100%;
    height: auto;
  }
}
.manage-card.horizontal__card-tip-detail .manage-card__content {
  margin: 20px 0;
  margin-bottom: 10px;
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  padding-bottom: 33px;
}
@media (max-width: 768px) {
  .manage-card.horizontal__card-tip-detail .manage-card__content {
    width: 100%;
  }
}
.manage-card.horizontal__card-tip-detail .manage-card__content h3 {
  margin-top: 0;
}
.manage-card.horizontal__card-tip-detail .manage-card__content p {
  word-break: break-all;
}
.manage-card.horizontal__card-tip-detail .manage-card__content .manage-card__inner-contact {
  border-top: 1px solid #E7E9EF;
  margin-top: 20px;
  padding: 20px 0;
}
.manage-card.horizontal__card-tip-detail .manage-card__content .manage-card__inner-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - 40px);
  margin: 0 20px;
  padding-top: 10px;
  border-top: 1px solid #E7E9EF;
}
.manage-card.horizontal__card-tip-other {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 150px;
}
.manage-card.horizontal__card-tip-other .manage-card__header {
  min-height: 200px;
  height: 100%;
}
@media (max-width: 768px) {
  .manage-card.horizontal__card-tip-other .manage-card__header {
    width: 100%;
    height: auto;
  }
}
.manage-card.horizontal__card-tip-other .manage-card__content {
  margin: 20px 0;
  margin-bottom: 10px;
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  padding-bottom: 33px;
}
@media (max-width: 768px) {
  .manage-card.horizontal__card-tip-other .manage-card__content {
    width: 100%;
  }
}
.manage-card.horizontal__card-tip-other .manage-card__content h3 {
  margin-top: 0;
}
.manage-card.horizontal__card-tip-other .manage-card__content p {
  word-break: break-all;
}
.manage-card.horizontal__card-tip-other .manage-card__content .manage-card__inner-contact {
  border-top: 1px solid #E7E9EF;
  margin-top: 20px;
  padding: 20px 0;
}
.manage-card.horizontal__card-tip-other .manage-card__content .manage-card__inner-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - 40px);
  margin: 0 20px;
  padding-top: 10px;
  border-top: 1px solid #E7E9EF;
}
.manage-card .special-col {
  width: calc(100% - 358px);
  justify-content: center;
  display: flex;
}
@media (max-width: 768px) {
  .manage-card .special-col {
    width: 100%;
    margin-top: 20px;
  }
}
.manage-card-list-3column {
  width: 100%;
  margin-bottom: 20px;
  grid-gap: 20px !important;
}
.manage-card-list-3column .manage-card {
  margin: 0;
  flex: 1;
}
.manage-card-list-3column .manage-card > div.ant-space {
  padding: 10px 0;
}
.manage-card-list-3column .manage-card__content h3 {
  font-weight: 600;
}
@media (max-width: 1280px) {
  .manage-card-list-3column .manage-card {
    flex: 1 1 auto;
  }
}
@media (max-width: 768px) {
  .manage-card-list-3column .manage-card.w-img {
    flex: 1;
    min-width: 200px !important;
  }
  .manage-card-list-3column .manage-card.w-img .ant-space {
    padding: 20px;
  }
  .manage-card-list-3column .manage-card.w-img .ant-space-item {
    width: 100%;
  }
  .manage-card-list-3column .manage-card__content {
    padding: 0;
  }
  .manage-card-list-3column .manage-card__content h3 {
    margin-top: 20px;
  }
  .manage-card-list-3column .manage-card__content > div {
    display: none;
  }
  .manage-card-list-3column .manage-card__content label.summary {
    max-width: unset;
    margin-bottom: 0;
  }
}
.manage-card-list-3column.column-special {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
@media (max-width: 768px) {
  .manage-card-list-3column.column-special {
    display: flex;
    grid-template-columns: unset;
  }
}
@media (max-width: 768px) {
  .manage-card-list {
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;
  }
  .manage-card-list .manage-card {
    min-width: 270px;
  }
  .manage-card-list .manage-card.w-img {
    min-width: 180px;
  }
  .manage-card-list .manage-card.w-img .ant-space {
    flex-direction: column-reverse;
    padding-top: 20px;
  }
  .manage-card-list .manage-card.w-img .ant-space.n-reverse {
    padding-top: 0;
    flex-direction: column;
  }
}
.wrapped-selection-popup .ant-select-item-option-content {
  white-space: pre-wrap;
  max-width: 500px;
}
@media (max-width: 500px) {
  .wrapped-selection-popup .ant-select-item-option-content {
    white-space: pre-wrap;
    max-width: 200px;
  }
}
.sidebar {
  height: 100vh;
}
@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 10000;
  }
  .sidebar.ant-layout-sider-collapsed {
    margin-left: -80px;
  }
}
.sidebar--overlay {
  content: '';
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
}
.category-card {
  height: auto;
  padding: 20px 10px 20px 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  background: white;
  align-items: center;
}
.category-card .sections {
  border-left: 2px solid #E7E9EF;
}
@media (max-width: 500px) {
  .category-card .sections {
    border-left: none;
  }
}
.category-card .sections .content-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 500px) {
  .category-card .sections .content-1 {
    justify-content: start;
    padding-left: 30px;
  }
}
.category-card .sections .content-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.category-card .sections .content-2.content-action {
  padding: 25px 0px 20px 0px;
}
@media (max-width: 500px) {
  .category-card .sections .content-2.content-action {
    margin-right: 20px;
    padding: 20px 0px 10px 0px;
    justify-content: center;
  }
}
@media (max-width: 500px) {
  .category-card .sections .content-2 {
    justify-content: start;
    padding: 20px 0px;
  }
}
.category-card .category-card_name {
  font: normal normal 600 16px/24px Avenir;
}
.category-card .category-card_mini-label {
  font: normal normal normal 16px/24px Avenir;
  color: #A8A8A8;
}
@media (max-width: 500px) {
  .category-card .category-card_mini-label {
    font: normal normal 600 24px/32px Avenir;
  }
}
.category-card .category-card_label {
  font: normal normal 600 30px/46px Avenir;
  color: #A8A8A8;
}
.category-card .category-card_actions {
  font: normal normal normal 16px/24px Avenir;
  color: #A8A8A8;
  cursor: pointer;
}
@media (max-width: 500px) {
  .category-card .category-card_actions {
    font: normal normal 600 16px/24px Avenir;
  }
}
.category-card .category-card_actions:hover {
  color: #00AD4C;
}
.custom-container-header {
  font: normal normal 600 16px/24px Avenir;
  color: #014050;
}
.custom-container-header .header-sections {
  width: auto;
}
@media (max-width: 500px) {
  .custom-container-header .header-sections {
    width: 100%;
  }
  .custom-container-header .header-sections.section-1 {
    display: none;
  }
}
.custom-container-header .txt-value {
  font: normal normal normal 16px/24px Avenir;
  color: #00AD4C;
}
.custom-container-header .txt-value.normal-24-600 {
  font: normal normal 600 16px/24px Avenir;
}
.custom-container-header .actions {
  font: normal normal normal 16px/24px Avenir;
  cursor: pointer;
}
.custom-container-content {
  font: normal normal normal 16px/24px Avenir;
  color: #014050;
}
.custom-container-content .title {
  font: normal normal 600 24px/32px Avenir;
  margin-bottom: 10px;
}
.custom-container-content .area-headers {
  font: normal normal 600 16px/24px Avenir;
  margin-bottom: 20px;
}
.custom-container-content .labels {
  color: #A8A8A8;
}
.custom-container-content .column-headers {
  color: #4A7E9F;
  font: normal normal medium 16px/24px Avenir;
}
.custom-container-content .column-values {
  color: #4A7E9F;
  font: normal normal 600 24px/32px Avenir;
}
.custom-container-content .txt-value {
  color: #00AD4C;
}
.custom-container-content .txt-value.normal-24-600 {
  font: normal normal 600 16px/24px Avenir;
}
.sort-num-indicator {
  width: 38px;
  height: 38px;
  display: flex;
  color: white;
  border-radius: 50px;
  align-items: center;
  background: #00AD4C;
  justify-content: center;
}
@media (max-width: 768px) {
  .actions-footer-lg {
    display: none;
  }
}
@media (min-width: 769px) {
  .actions-footer-xs {
    display: none;
  }
}
.add-on-label {
  font: normal normal medium 16px/24px Avenir;
  font-weight: bold;
  color: #014050;
  margin-right: 20px;
}
@media (max-width: 426px) {
  .add-on-label {
    display: none;
  }
}
.add-on-select {
  width: 50%;
}
@media (max-width: 426px) {
  .add-on-select {
    width: 100%;
  }
}
.item-image-border-radius {
  border-radius: 6px;
  object-fit: cover !important;
}
.item-image-border-radius.image_placeholder {
  object-fit: contain !important;
}
@media (max-width: 768px) {
  .item-image-border-radius {
    max-height: 300px;
  }
}
.item-highlight-detail-container {
  padding: 0px 0 0px 40px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .item-highlight-detail-container {
    padding: 10px 0;
  }
}
@media (max-width: 426px) {
  .item-highlight-detail-container {
    padding: 10px 5px;
  }
}
.item-highlight-detail-container .item-highlight-title {
  font: normal normal 600 24px/32px Avenir;
}
.item-highlight-detail-container .item-highlight-category {
  font: normal normal normal 14px/24px Avenir;
}
.item-highlight-detail-container .item-highlight-description {
  color: #A8A8A8;
  font: normal normal normal 14px/20px Avenir;
}
.item-highlight-detail-container .item-highlight-actions-container {
  margin-top: auto;
  color: #A8A8A8;
}
.item-highlight-detail-container .item-highlight-actions {
  cursor: pointer;
}
.item-highlight-detail-container .item-highlight-actions:hover {
  color: #6930a3;
  transition: 0.3s ease-in-out;
}
.item-highlight-detail-container .item-highlight-actions-detail-btn span {
  font-weight: 600;
  border-bottom: 2px solid #A8A8A8;
}
.item-highlight-detail-container .item-highlight-actions-detail-btn span:hover {
  transition: 0.3s ease-in-out;
  border-bottom: 2px solid #6930a3;
}
.item-detail-container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  border-right: 1px solid #dfdfdf;
}
.item-detail-container:nth-child(3n) {
  border-right: none;
}
.item-detail-container:nth-child(-n+3) {
  padding-top: 0 !important;
}
@media (max-width: 991px) {
  .item-detail-container:nth-child(even) {
    border-right: none;
  }
  .item-detail-container:nth-child(odd) {
    border-right: 1px solid #dfdfdf !important;
  }
  .item-detail-container:nth-child(-n+2) {
    padding-top: 0 !important;
  }
  .item-detail-container:nth-child(3) {
    padding-top: 30px !important;
  }
}
@media (max-width: 768px) {
  .item-detail-container:nth-child(2) {
    padding-top: 30px !important;
  }
}
@media (max-width: 767px) {
  .item-detail-container:nth-child(odd) {
    border-right: none !important;
  }
}
.item-detail-container .item-title {
  font: normal normal 600 16px/24px Avenir;
}
.item-detail-container .item-category {
  font: normal normal normal 14px/24px Avenir;
}
.item-detail-container .item-actions-container {
  margin-top: auto;
  padding-top: 10px;
  color: #A8A8A8;
}
.item-detail-container .item-actions {
  cursor: pointer;
}
.item-detail-container .item-actions:hover {
  color: #00ad4c;
}
.item-detail-container .item-actions-detail-btn span {
  font-weight: 600;
  border-bottom: 2px solid #A8A8A8;
}
.item-detail-container .item-actions-detail-btn span:hover {
  transition: 0.3s ease-in-out;
  border-bottom: 2px solid #6930a3;
}
@media (min-width: 992px) {
  .tips__suggested-prod {
    padding: 0 0 0 10px;
  }
}
@media (max-width: 991px) {
  .tips__suggested-prod {
    padding-top: 10px;
  }
}
.tips__suggested-prod .ant-table-empty {
  background-color: #ffffff !important;
}
.tips__suggested-prod .ant-image-img {
  max-height: 200px;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
}
.tips__suggested-prod .ant-image-img.image_placeholder {
  object-fit: contain;
  background-color: #e7e9ef;
}
@media (max-width: 767px) {
  .tips__suggested-prod .ant-image-img {
    border-radius: 10px 10px 0 0;
  }
}
.tip-visible-xs {
  display: none;
}
@media (max-width: 991px) {
  .tip-hidden-xs {
    display: none;
  }
  .tip-visible-xs {
    display: block;
  }
}
.tip-linked-products-list {
  width: 100%;
  padding-bottom: 20px;
}
.tip-linked-products-list .linked-product-name {
  padding: 40px 20px 10px 30px;
}
.tip-linked-products-list .linked-product-name .product-name-txt {
  font: normal normal 600 16px/24px Avenir;
}
.tip-linked-products-list .linked-product-category {
  padding: 0px 30px;
}
.tip-linked-products-list .linked-product-category .product-category-txt {
  color: #A8A8A8;
}
.tip-linked-products-list .linked-product-actions {
  padding: 30px 20px 10px 30px;
}
.tip-linked-products-list .linked-product-actions .product-category-remove-btn {
  color: #A8A8A8;
  cursor: pointer;
}
.tip-linked-products-list .linked-product-actions .product-category-remove-btn:hover {
  color: #000025;
}
@media (max-width: 768px) {
  .tip-linked-products-list .linked-product-actions {
    margin: 100px 30px 0px 0px;
  }
}
@media (max-width: 426px) {
  .tip-linked-products-list .linked-product-actions {
    margin: 0px;
  }
}
.sd-pagination .ant-table {
  border: none;
  border-radius: 10px;
  overflow: hidden;
}
.sd-pagination .ant-table table tr {
  color: #1b144c;
  font-weight: 600;
}
.sd-pagination .ant-table span.c-pointer.link-primary {
  color: #B2A1A1;
}
.sd-pagination .ant-table span.c-pointer.link-primary:hover {
  color: #6930a3;
}
.sd-pagination .ant-table .table-actions span.c-pointer.link-primary {
  margin-right: 15px;
}
.sd-pagination .ant-pagination.ant-table-pagination,
.sd-pagination .ant-pagination.mini.ant-table-pagination {
  padding: 10px 0;
}
.sd-pagination .ant-pagination.mini.ant-table-pagination {
  display: flex;
  justify-content: end;
}
.sd-pagination .ant-table-thead > tr > th {
  background: none;
  color: #ffffff;
}
.sd-pagination .ant-table-thead {
  background-color: #1b144c;
}
.sd-pagination .ant-table-thead tr {
  background-color: #1b144c;
}
.sd-pagination .ant-table-container table > thead > tr:first-child th:first-child {
  padding: 25px;
}
.sd-pagination .ant-pagination-item,
.sd-pagination .ant-pagination-prev > button,
.sd-pagination .ant-pagination-next > button {
  border: none;
  color: #1b144c;
  font-weight: 700;
  margin-right: 6px;
  border-radius: 10px;
}
.sd-pagination .ant-pagination-disabled > button {
  border: none;
  box-shadow: none;
  color: #A8A8A8;
}
.sd-pagination .ant-pagination-item > a {
  font-family: 'Avenir';
  color: #1b144c;
}
.sd-pagination .ant-pagination-item-active {
  background-color: #6930a3;
  border: initial;
  font-family: 'Avenir';
}
.sd-pagination .ant-pagination-item-active a {
  color: white;
}
.sd-pagination .ant-pagination-item-active a:hover {
  color: white;
}
.sd-pagination .ant-pagination-prev .ant-pagination-item-link,
.sd-pagination .ant-pagination-next .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sd-pagination .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: transparent;
}
.sd-pagination .ant-pagination.mini .ant-pagination-disabled > button,
.sd-pagination .ant-pagination.mini .ant-pagination-next.ant-pagination-disabled > button {
  border: none;
  box-shadow: none;
  color: #A8A8A8;
  background-color: #ffffff;
  border-radius: 5px;
  margin-right: 5px;
}
.sd-pagination .ant-pagination.mini .ant-pagination-next > button {
  border: none;
  box-shadow: none;
  color: #000025;
  background-color: #ffffff;
  border-radius: 5px;
}
.sd-pagination .ant-pagination.mini .ant-pagination-prev {
  margin-right: 5px;
  border: none;
  box-shadow: none;
  color: #000025;
  background-color: #ffffff;
  border-radius: 5px;
}
.sd-pagination .ant-pagination.mini .ant-pagination-item-active {
  background-color: #6930a3;
  border: initial;
  border-radius: 5px;
  margin-right: 5px;
}
.sd-pagination .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: #ffffff;
  border-radius: 5px;
  margin-right: 5px;
}
@media (max-width: 426px) {
  .sd-pagination .ant-pagination {
    justify-content: center;
  }
}
.tips-card-actions {
  row-gap: 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 426px) {
  .tips-card-actions {
    margin-top: 20px;
    row-gap: 0px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
  }
}
.detail-container {
  width: 700px;
}
@media (max-width: 768px) {
  .detail-container {
    width: 100%;
  }
}
.detail-container .detail-image-container {
  width: 100%;
  height: 431px;
}
.detail-container .detail-image-container .ant-image-img {
  object-fit: cover;
}
.detail-container .detail-image-container .ant-image-img.image_placeholder {
  object-fit: contain;
}
.detail-content-container {
  padding: 20px 40px 20px 40px;
}
@media (max-width: 768px) {
  .detail-content-container {
    padding: 20px;
  }
}
@media (max-width: 426px) {
  .search-header {
    margin-bottom: 20px;
  }
}
.ant-layout-content {
  overflow-x: clip;
}
.ant-layout-content .swipeable-stacks-container-v2 {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  max-height: 600px;
  width: 80vw !important;
}
@media (max-width: 280px) {
  .ant-layout-content .swipeable-stacks-container-v2 {
    width: 85vw !important;
  }
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card {
  max-width: 360px;
  max-height: 600px;
  width: 80vw !important;
  height: 70vh !important;
  border-radius: 25px;
  box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: none;
}
@media (max-width: 768px) {
  .ant-layout-content .swipeable-stacks-container-v2 .activity-card {
    height: 55vh !important;
    width: 75vw !important;
  }
}
@media (max-width: 576px) {
  .ant-layout-content .swipeable-stacks-container-v2 .activity-card {
    height: 50vh !important;
    width: 75vw !important;
  }
}
@media (max-width: 426px) {
  .ant-layout-content .swipeable-stacks-container-v2 .activity-card {
    height: 50vh !important;
    width: 75vw !important;
  }
}
@media (max-width: 280px) {
  .ant-layout-content .swipeable-stacks-container-v2 .activity-card {
    height: 45vh !important;
    width: 80vw !important;
  }
}
@supports (-webkit-touch-callout: none) {
  .ant-layout-content .swipeable-stacks-container-v2 .activity-card {
    /* CSS specific to iOS devices */
  }
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body {
  padding: 0;
  height: 100%;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .title,
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .description {
  margin: 0;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .title {
  color: #ffffff !important;
  font-size: 1.15rem;
  margin-bottom: 8px;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .description {
  color: #B2A1A1 !important;
  font-size: 0.9rem;
  margin-bottom: 12px;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .full-description {
  color: #B2A1A1 !important;
  font-weight: 400;
  overflow: hidden;
  position: relative;
  line-height: 1.5em;
  max-height: 7.5em;
  text-align: start;
  margin-right: -1em;
  padding-right: 1em;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  font-size: 20px;
  white-space: pre-wrap;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .full-description:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .full-description:after {
  content: '';
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: transparent;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .more-info {
  color: #6930a3 !important;
  font-size: 14px;
  font-weight: 600;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .discount-info-container {
  position: absolute;
  right: 10px;
  top: 10px;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .discount-info-container .discount-info {
  margin: 0;
  padding: 5px 15px;
  color: #ffffff;
  background-color: red;
  border-radius: 5px;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .activity-counter {
  background-color: #6930a3;
  width: 80px;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  top: 0;
  right: 0;
  margin: 10px;
  position: absolute;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .activity-image {
  min-height: 200px;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  padding: 0 8px 8px;
  position: 'relative';
  background-size: cover !important;
  background-position: center !important;
}
@media (max-width: 280px) {
  .ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .activity-image {
    min-height: 100px;
  }
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .activity-image .discount-info-container {
  position: absolute;
  right: 10px;
  top: 10px;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .activity-image .discount-info-container .discount-info {
  margin: 0;
  padding: 5px 15px;
  color: #ffffff;
  background-color: red;
  border-radius: 5px;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .activity-image .voucher-title {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  margin-bottom: 0;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .activity-image .voucher-info {
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  margin-bottom: 0;
  margin-top: 10px;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .activity-image .voucher-info.default {
  font-weight: 400;
}
.ant-layout-content .swipeable-stacks-container-v2 .activity-card .ant-card-body .activity-image .divider {
  height: 25px;
  border-left: 1px solid #B2A1A1;
  opacity: 0.5;
  margin-bottom: 10px;
}
.ant-layout-content .swipeable-stacks-container-v2 .actions-container {
  padding: 15px 20px;
  border-radius: 50px;
  background-color: #1b144c;
  box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.1);
}
.ant-layout-content .swipeable-stacks-container-v2 .actions-container .action-divider {
  border-left: 1px solid #B2A1A1;
  opacity: 0.5;
  height: 1.9em;
}
.ant-layout-content .swipeable-stacks-container-v2 .actions-container .anticon.anticon-reload.action-item {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
}
.ant-layout-content .swipeable-stacks-container-v2 .actions-container .anticon.anticon-reload.action-item svg {
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1) translateY(2px);
}
.ant-layout-content .swipeable-stacks-container-v2 .actions-container .anticon.anticon-check.action-item {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
}
.ant-layout-content .swipeable-stacks-container-v2 .actions-container .anticon.anticon-check.action-item svg {
  transform: translateY(4px);
}
.ant-layout-content .swipeable-stacks-container-v2 .actions-container .anticon.anticon-close.action-item {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
}
.ant-layout-content .swipeable-stacks-container-v2 .actions-container .icon-container {
  background-color: #ffffff;
  border-radius: 35px;
  box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-layout-content .swipeable-stacks-container-v2 .actions-container .icon-container.close {
  background-color: #EA3B3B;
  cursor: pointer;
}
.ant-layout-content .swipeable-stacks-container-v2 .actions-container .icon-container.check {
  background-color: #52C41A;
  cursor: pointer;
}
.ant-layout-content .swipeable-stacks-container-v2 .actions-container .icon-container.redo {
  background-color: #cccccc;
  cursor: pointer;
}
.ant-layout-content .swipeable-stacks-container-v2 .actions-container .ant-space-item:nth-child(1) > .icon-container > .inner > span {
  margin-top: 5px;
  padding: 0 3px;
}
.ant-layout-content .swipeable-stacks-container-v2 .actions-container .ant-space-item:nth-child(3) > .icon-container > .inner > span {
  margin-bottom: 5px;
  padding: 0 3px;
}
.ant-layout-content .swipeable-stacks-container .activity-card {
  width: 325px;
  height: 380px;
  border-radius: 15px;
  box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: none;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body {
  padding: 0;
  height: 100%;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .title,
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .description {
  margin: 0;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .title {
  color: #1b144c !important;
  font-size: 1.15rem;
  margin-bottom: 8px;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .description {
  color: #6930a3 !important;
  font-size: 0.9rem;
  margin-bottom: 12px;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .full-description {
  color: #B2A1A1 !important;
  font-weight: 400;
  overflow: hidden;
  position: relative;
  line-height: 1.5em;
  max-height: 7.5em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .full-description:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .full-description:after {
  content: '';
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: transparent;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .more-info {
  color: #6930a3 !important;
  font-size: 14px;
  font-weight: 600;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .discount-info-container {
  position: absolute;
  right: 10px;
  top: 10px;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .discount-info-container .discount-info {
  margin: 0;
  padding: 5px 15px;
  color: #ffffff;
  background-color: red;
  border-radius: 5px;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .activity-image {
  min-height: 200px;
  height: 43%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  padding: 0 8px 8px;
  position: 'relative';
  background-size: cover !important;
  background-position: center !important;
}
@media (max-width: 280px) {
  .ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .activity-image {
    min-height: 100px;
  }
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .activity-image .discount-info-container {
  position: absolute;
  right: 10px;
  top: 10px;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .activity-image .discount-info-container .discount-info {
  margin: 0;
  padding: 5px 15px;
  color: #ffffff;
  background-color: red;
  border-radius: 5px;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .activity-image .voucher-title {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  margin-bottom: 0;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .activity-image .voucher-info {
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  margin-bottom: 0;
  margin-top: 10px;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .activity-image .voucher-info.default {
  font-weight: 400;
}
.ant-layout-content .swipeable-stacks-container .activity-card .ant-card-body .activity-image .divider {
  height: 25px;
  border-left: 1px solid #B2A1A1;
  opacity: 0.5;
  margin-bottom: 10px;
}
.ant-layout-content .swipeable-stacks-container .actions-container {
  padding: 15px 20px;
  border-radius: 50px;
  background-color: #1b144c;
  box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.1);
}
.ant-layout-content .swipeable-stacks-container .actions-container .action-divider {
  border-left: 1px solid #B2A1A1;
  opacity: 0.5;
  height: 1.9em;
}
.ant-layout-content .swipeable-stacks-container .actions-container .icon-container {
  background-color: #ffffff;
  border-radius: 35px;
  box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.1);
}
.ant-layout-content .swipeable-stacks-container .actions-container .ant-space-item:nth-child(1) > .icon-container > .inner > span {
  margin-top: 5px;
  padding: 0 3px;
}
.ant-layout-content .swipeable-stacks-container .actions-container .ant-space-item:nth-child(3) > .icon-container > .inner > span {
  margin-bottom: 5px;
  padding: 0 3px;
}
.ant-layout-content .actions-container {
  padding: 15px 20px;
  border-radius: 50px;
  background-color: #1b144c;
  box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.1);
}
.ant-layout-content .actions-container .action-divider {
  border-left: 1px solid #B2A1A1;
  opacity: 0.5;
  height: 1.9em;
}
.ant-layout-content .actions-container .anticon.anticon-reload.action-item {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
}
.ant-layout-content .actions-container .anticon.anticon-reload.action-item svg {
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1) translateY(2px);
}
.ant-layout-content .actions-container .anticon.anticon-check.action-item {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
}
.ant-layout-content .actions-container .anticon.anticon-check.action-item svg {
  transform: translateY(4px);
}
.ant-layout-content .actions-container .anticon.anticon-close.action-item {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
}
.ant-layout-content .actions-container .icon-container {
  background-color: #ffffff;
  border-radius: 35px;
  box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-layout-content .actions-container .icon-container.close {
  background-color: #EA3B3B;
  cursor: pointer;
}
.ant-layout-content .actions-container .icon-container.check {
  background-color: #52C41A;
  cursor: pointer;
}
.ant-layout-content .actions-container .icon-container.redo {
  background-color: #cccccc;
  cursor: pointer;
}
.ant-layout-content .actions-container .ant-space-item:nth-child(1) > .icon-container > .inner > span {
  margin-top: 5px;
  padding: 0 3px;
}
.ant-layout-content .actions-container .ant-space-item:nth-child(3) > .icon-container > .inner > span {
  margin-bottom: 5px;
  padding: 0 3px;
}
.reordering-list-container .activity-type-dropdown {
  background: #ffffff;
  border: none;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
}
.reordering-list-container .activity-type-dropdown .dropdown-trigger {
  height: auto;
}
@media (max-width: 576px) {
  .reordering-list-container .activity-type-dropdown .ant-card-body {
    padding: 15px;
  }
}
.reordering-list-container .activity-type-dropdown .ant-btn.tag-buttons:not(.ant-btn-primary) span {
  color: #1b144c;
}
.reordering-list-container .activity-type-dropdown .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6930a3;
  border-color: #6930a3;
}
.reordering-list-container .activity-type-dropdown.checked {
  background: #6930a3;
}
.reordering-list-container .activity-type-dropdown.checked .ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #6930a3 !important;
}
.reordering-list-container .activity-type-dropdown.checked .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff;
  border-color: #ffffff;
}
.reordering-list-container .activity-type-dropdown.checked .title {
  color: #ffffff !important;
}
.reordering-list-container .activity-type-dropdown.checked .dropdown-trigger {
  color: #ffffff;
}
.reordering-list-container .activity-type-dropdown .tags-popover-content .item {
  padding: 8px 10px;
  background-color: #6930a3;
  border-radius: 8px;
  margin-right: 10px;
  color: #ffffff;
}
.reordering-list-container .activity-type-dropdown .divider {
  border: 1px solid #B2A1A1;
  min-height: 50px;
  margin-left: 15px;
  margin-right: 15px;
  opacity: 0.5;
}
@media (max-width: 576px) {
  .reordering-list-container .activity-type-dropdown .divider {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.reordering-list-container .activity-type-dropdown .title {
  font-size: 14px;
  color: #1b144c !important;
  font-weight: 600;
}
.reordering-list-container .activity-type-dropdown .dropdown-trigger {
  padding: 0;
  box-shadow: none !important;
  background: none;
  border: none;
  color: #1b144c;
}
@media (max-width: 360px) {
  .reordering-list-container .activity-type-dropdown .dropdown-trigger {
    width: 120px;
    white-space: normal;
    text-align: left;
    line-height: 15px;
  }
}
.reordering-list-container .activity-type-dropdown .dropdown-trigger > span {
  font-weight: 400;
  font-size: 14px;
}
@media (max-width: 576px) {
  .reordering-list-container .activity-type-dropdown .dropdown-trigger > span {
    font-size: 12px;
  }
}
.reordering-list-container .activity-type-dropdown .dropdown-trigger:hover {
  background: none;
  border: none;
}
.reordering-list-container .activity-type-dropdown .ant-popover {
  left: -80px !important;
  z-index: 2 !important;
}
@media (max-width: 576px) {
  .reordering-list-container .activity-type-dropdown .ant-popover {
    left: -60.5px !important;
  }
}
.reordering-list-container .activity-type-dropdown .ant-popover .ant-popover-arrow > .ant-popover-arrow-content {
  background-color: transparent !important;
}
/* StripeCheckoutForm.less */
.stripe-form-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3em;
}
.stripe-form-container img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .stripe-form-container {
    width: 400px;
  }
}
@media (max-width: 426px) {
  .stripe-form-container {
    width: 300px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.form {
  width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}
@media (max-width: 768px) {
  .form {
    width: 400px;
  }
}
@media (max-width: 426px) {
  .form {
    width: 300px;
  }
}
.stripe-success-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 20%;
  margin-right: 20%;
  height: 100vh;
  gap: 3em;
  text-align: center;
}
.stripe-form-reservation-title-container {
  width: "80%";
  word-break: break-all;
}
.stripe-form-text {
  color: #ffffff;
}
.stripe-form-text-bold {
  color: #ffffff;
  font-weight: 900;
}
