
// Graphs Customizations
.graph {

  &__legends {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;

    .legend {
      display: flex;
      align-items: center;
    }
    .legend-color {
      height: 16px;
      width: 16px;
      min-width: 16px;
      max-height: 16px;
    }

    .legend-label {
      font-size: 12px;
      line-height: 100%;
      margin-left: .3rem;
      font-weight: normal;
      white-space: nowrap;
      color: @secondary-color;
    }
  }
}