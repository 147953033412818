.single-values-field {
  min-width: 40%;
  max-width: 40%;

  @media (max-width: 500px) {
    min-width: 100%;
    max-width: 100%;
  }
}

.single-values-multi-field {
  max-width: 40%;
  width: 100%;

  @media (max-width: 500px) {
    max-width: 100%;
  }
}

.multiple-values-multi-field {
  // padding-right: 20px;
  width: 100%;

  @media (max-width: 500px) {
    padding-right: 0px;
  }
}

.multiple-values-remove-btn {
  cursor: pointer;
}

.multiple-values-action {
  margin-right: 20px;
  display: flex;
  align-items: flex-end;
  padding: 0px 0px 5px 0px;

  @media @max768 {
    margin-top: 30px;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 20px;
  }

  @media (max-width: 500px) {
    padding-right: 0px;
  }
}