.custom-table-pagination {
  background-color: none !important;

  .ant-table-cell {
    padding: 0px;
  }

  .ant-table-cell:hover {
    background: none !important;
  }

  .ant-table {
    background: none !important;
  }

}