html,body {
  overscroll-behavior-y: none;
}

body {
    margin: 0;
    font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: radial-gradient(closest-side at 50% 50%, #1B144C 0%, #000025 100%) 0% 0% no-repeat padding-box;
    background-color: #000025;
    
    &.ant-scrolling-effect:has(.modal-notifications) {
      overflow-y: auto !important;
      width: 100% !important;
    }

    text-rendering: optimizeLegibility;
    
  }



h1, h2 {
  font-weight: 600;
}



.main-layout, main.ant-layout-content {
  background: radial-gradient(closest-side at 50% 50%, #1B144C 0%, #000025 100%) 0% 0% no-repeat padding-box;
}


.d-header {
  position: sticky;
  top: 0;
  z-index: 3;
  background: @body;
  padding-bottom: .5rem;
}


/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill, 
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: @primary-color;
  
  &::selection {
    background-color: @primary-color;
    -webkit-text-fill-color: @white;
  }

  &::-moz-selection {
    background-color: @primary-color;
    -webkit-text-fill-color: @white;
  }

  transition: background-color 5000s ease-in-out 0s;
}