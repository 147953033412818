.wrapped-selection-popup {
  .ant-select-item-option-content {
    white-space: pre-wrap;
    max-width: 500px;

    @media (max-width: 500px) {
      white-space: pre-wrap;
      max-width: 200px;
    }
  }
}