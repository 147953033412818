.manage-no-data-placeholder {
  th, td {
    background: none !important;
    border: none !important;
  }
  
  .ant-table {
    background: none !important;
  }

  @media @min768 {
    .ant-empty-description {
      font-size: 17.5px !important;
    }
  
    .ant-empty-image {
      height: 100px !important;
    }
  
    .ant-empty-image svg {
      width: 120px;
      height: 100px;
    }
  }
}

.details-no-data-placeholder {
  th, td {
    background: none !important;
    border: none !important;
  }
  
  .ant-table {
    background: none !important;
  }

  @media @min768 {
    .ant-empty-description {
      font-size: 17.5px !important;
    }
  
    .ant-empty-image {
      height: 100px !important;
    }
  
    .ant-empty-image svg {
      width: 120px;
      height: 100px;
    }
  }

  @media (max-width: 426px) {
    .ant-empty-description {
      font-size: 17.5px !important;
    }
  
    .ant-empty-image {
      height: 100px !important;
    }
  
    .ant-empty-image svg {
      width: 325px;
      height: 75px;
    }
  }

  @media (max-width: 376px) {
    .ant-empty-description {
      font-size: 17.5px !important;
    }
  
    .ant-empty-image {
      height: 100px !important;
    }
  
    .ant-empty-image svg {
      width: 280px;
      height: 75px;
    }
  }

  @media (max-width: 321px) {
    .ant-empty-description {
      font-size: 17px !important;
    }
  
    .ant-empty-image {
      height: 75px !important;
    }
  
    .ant-empty-image svg {
      width: 225px;
      height: 60px;
    }
  }
}