

.reordering-list-container {



    //Items
    .activity-type-dropdown {
        background: @white;
        border: none;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;

        .dropdown-trigger {
            height: auto;
        }

        @media @max576{

            .ant-card-body {
                padding: 15px;
            }
        }

        

        .ant-btn.tag-buttons:not(.ant-btn-primary){
            span {
                color: @secondary-color;
            }
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: @primary-color;
            border-color: @primary-color;
        }

        

        &.checked {
            background: @primary-color;

            .ant-checkbox-checked .ant-checkbox-inner:after {
                border-color: @primary-color !important;
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: @white;
                border-color: @white;
            }

            .title {
                color: @white !important;
            }

            .dropdown-trigger {
                color: @white;
            }
        }


        

        .tags-popover-content {

            .item {
                padding: 8px 10px;
                background-color: @primary-color;
                border-radius: 8px;
                margin-right: 10px;
                color: @white;
            }
        }

        .divider {
            border: 1px solid @dark-gray;
            min-height: 50px;
            margin-left: 15px;
            margin-right: 15px;
            opacity: 0.5;

            @media @max576 {
                margin-left: 10px;
                margin-right: 10px;
            }
        }

        .title {
            font-size: 14px;
            color: @secondary-color !important;
            font-weight: 600;
        }

        .dropdown-trigger {
            padding: 0;
            box-shadow: none !important;
            background: none;
            border: none;
            color: @secondary-color;

            @media @max360 {
                width: 120px;
                white-space: normal;
                text-align: left;
                line-height: 15px;
            }
            
            & > span {
                font-weight: 400;
                font-size: 14px;

                @media @max576{
                    font-size: 12px;
                }
            }
            

            &:hover {
                background: none;
                border: none;
            }
        }

        

        .ant-popover {
            left: -80px !important;
            z-index: 2 !important;

            @media @max576 {
                left: -60.5px !important;
            }

            .ant-popover-arrow > .ant-popover-arrow-content {
                background-color: transparent !important;
            }
        }
    }
}