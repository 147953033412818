@import '../../../../assets/less/common/variables.less';

// .logintxt {
//     @media @max426 {
//         font-size: x-small;
//         right: 30px;
//         position: absolute;
//         right: 1px;
//     }
// }

.footer-container {
    background-color: @secondary-color;
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 25px;

    @media @max768 {
        padding-bottom: 25px;

        .footer-small-icon > div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @media @max767 {
        .footer-small-icon {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &.mobile-auth {
        @media @max768 {
            padding-bottom: 89px;
        }
    }

    
}

// .footer-logo {
//     @media @max426 {
//         display: none;
//     }
// }

.about-us {
    color: white;

    @media @max426 {
        font-size: small;
    }
}

.support {
    color: white;

    @media @max426 {
        font-size: small;
        margin-bottom: 20px;
    }
}

.social-media-txt {
    color: @white;
    margin: 0;
    margin-top: 2px;
}

.twitter-icon {
    @media @max426 {
        position: absolute;
        left: 40px;
        top: -22px;
    }
}

.instagram-icon {
    @media @max426 {
        position: absolute;
        left: 80px;
        top: -22px;
    }
}

.location-div {
    @media @max426 {
        width: 260px;
        font-size: x-small;
    }

    @media @max320 {
        width: auto;
    }
}

.phone-div {
    @media @max426 {
        width: 260px;
        top: 80px;
        font-size: x-small;
    }

    @media @max320 {
        width: auto;
    }
}

.email-div {
    @media @max426 {
        width: 260px;
        top: 100px;
        font-size: x-small;
    }

    @media @max320 {
        width: auto;
    }
}

.footer-divider {
    border-left: 1px solid @primary-color;
    height: 1em;
}

.footer-terms-divider {
    margin: 12px 0;
    border-top: 1px solid @primary-color;
}

.tiktok-icon{
    svg{
        width: 14px;
        height: 14px;
        margin-top: 5px
    }
}

@primary-color: #6930a3;@secondary-color: #1b144c;@tertiary-color: #000025;@border-radius-base: 5px;