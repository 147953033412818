@{categoryCard} {
  height: auto;
  padding: 20px 10px 20px 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  background: white;
  align-items: center;

  .sections {
    border-left: 2px solid #E7E9EF;

    @media (max-width: 500px) {
      border-left: none;
    }
    
    .content-1 {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 500px) {
        justify-content: start;
        padding-left: 30px;
      }
    }

    .content-2 {
      display: flex;
      align-items: center;
      justify-content: center;

    
      &.content-action {
        padding: 25px 0px 20px 0px;
        
        @media (max-width: 500px) {
          margin-right: 20px;
          padding: 20px 0px 10px 0px;
          justify-content: center;
        }
      }

      @media (max-width: 500px) {
        justify-content: start;
        padding: 20px 0px;
      }
    }
  }

  @{categoryCard}_name {
    font: @normal24px600;
  }

  @{categoryCard}_mini-label {
    font: @min24px;
    color: #A8A8A8;

    @media (max-width: 500px) {
      font: @max32px;
    }
  }

  @{categoryCard}_label {
    font: @max46px;
    color: #A8A8A8;
  }

  @{categoryCard}_actions {
    font: @min24px;
    color: #A8A8A8;
    cursor: pointer;

    @media (max-width: 500px) {
      font: @normal24px600;
    }
  }

  @{categoryCard}_actions:hover {
    color: #00AD4C;
  }
}

.custom-container-header {
  font: @normal24px600;
  color: #014050;

  .header-sections {
    width: auto;

    @media (max-width: 500px) {
      width: 100%;

      &.section-1 {
        display: none
      }
    }
  }

  .txt-value {
    font: @normal24px;
    color: #00AD4C;

    &.normal-24-600 {
      font: @normal24px600;
    }
  }

  .actions {
    font: @min24px;
    cursor: pointer;
  }
}

.custom-container-content {
  font: @min24px;
  color: #014050;

  .title {
    font: @max32px;
    margin-bottom: 10px;
  }

  .area-headers {
    font: @normal24px600;
    margin-bottom: 20px;
  }

  .labels {
    color: #A8A8A8;
  }

  .column-headers {
    color: #4A7E9F;
    font: @med24px;
  }

  .column-values {
    color: #4A7E9F;
    font: @max32px;
  } 

  .txt-value {
    color: #00AD4C;

    &.normal-24-600 {
      font: @normal24px600;
    }
  }
}

.sort-num-indicator {
  width: 38px;
  height: 38px;
  display: flex;
  color: white;
  border-radius: 50px;
  align-items: center;
  background: #00AD4C;
  justify-content: center;
}

.actions-footer-lg {
  @media @max768 {
    display: none;
  }
}

.actions-footer-xs {
  @media (min-width: 769px) {
    display: none;
  }
}

@categoryCard: .category-card;