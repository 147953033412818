@import '../../../views-dir.less';

.sidebar-root {
  height: 100vh;
  flex-direction: column;
  background: radial-gradient(closest-side at 50% 50%, #1B144C 0%, #000025 100%) 0% 0% no-repeat padding-box;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 350px;

  .logo-container {
    text-align: center;

    @media @max768 {
      text-align: left;
    }
  }

  @media @max768 {
    height: 100%;
    flex-direction: column;
    background: radial-gradient(closest-side at 50% 50%, #1B144C 0%, #000025 100%) 0% 0% no-repeat padding-box;
    position: relative;
    width: auto;
  }

  .sidebar-trigger-icon {
    font-size: 150%;
    color: @primary-color;
  }

  .ant-menu-inline.ant-menu-light {
    background: transparent;
  }

  .profile-info {
    width: 80%;
    padding: .5rem 0;
    overflow: hidden;
    margin: auto auto 0;
    border-top: 1px solid #E7E9EF;
    transition: width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);

    .ant-list-item-meta {
      align-items: center;
    }
    .ant-list-item-meta-avatar {
      margin-right: 12px;
    }
    .ant-list-item-meta-content {
      width: 100%;
      opacity: 1;
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    }
    .ant-list-item-meta-title {
      margin: 0;
      font-size: 18px;
      color: @secondary-color;

      width: 105px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ant-list-item-meta-description {
      color: @dark-gray;
      
      >span {
        position: relative;
        .anticon {
          position: absolute;
          top: 4px;
          right: -18px;
        }
      }
    }
  }

  .sidebar-bottom-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 25px;
  }
  
}

.ant-layout-sider-collapsed {
  .sidebar-root {
    .ant-list-item-meta-content {
      width: 0;
      opacity: 0;
    }

    .ant-menu-inline-collapsed {
      background: transparent;
    }
  }
}
@primary-color: #6930a3;@secondary-color: #1b144c;@tertiary-color: #000025;@border-radius-base: 5px;